// store BO about/seminars
const MAX_CACHE_AGE_MS = 1000 * 60 * 3; // 3h

function sanitizeSeminar(seminar) {
    if (seminar && seminar.category_event_specific && seminar.category_event_specific.text) {
        // Fix for vue-editor that cannot handle divs.
        seminar.category_event_specific.text = seminar.category_event_specific.text.replace(/<div/g, "<p").replace(/<\/div>/g, "</p>");
    }
}

const state = {
    seminars: [],
    retrieveSeminarsFailed: false,
    lastRetrieveSeminarsSuccess: null,
    retrieveInBoMode: false
};
const getters = {
    getSeminars: state => {
        return state.seminars;
    },
    getSeminar: (state) => (id) => {
        return state.seminars.find(item => item.id == id);
    }
};
const mutations = {
    'SET_SEMINARS'(state, seminars) {
        state.seminars = seminars;
        state.seminars.forEach(seminar => sanitizeSeminar(seminar));
    },
    'SET_SEMINAR'(state, seminar) {
        let idx = state.seminars.findIndex(s => s.id == seminar.id);
        sanitizeSeminar(seminar);
        if (idx == -1) {
            state.seminars.push(seminar);
        } else {
            state.seminars[idx] = seminar;
        }
    },
    'DELETE_SEMINAR'(state, id) {
        state.seminars = state.seminars.filter(s => s.id != id);
    },
    'SET_LAST_RETRIEVE'(state, success) {
        if (success) {
            state.lastRetrieveSeminarsSuccess = new Date();
            state.retrieveSeminarsFailed = false;
        } else {
            state.retrieveSeminarsFailed = true;
        }
    },
    'SET_RETRIEVE_BO'(state, bo) {
        this.retrieveInBoMode = bo;
    },
};
const actions = {
    async retrieveSeminars({ commit, state }, { id, bo, full, force }) {
        const textInfo = full ? 1 : 0;
        const filter = 1;
        let result;

        if (state.retrieveInBoMode != bo) {
            // Currently cached seminars were not retrieved in BO mode
            // and are therefore invalid.
            force = true;
        }
        // Do nothing if cache is present and recent.
        if (!force && !state.retrieveSeminarsFailed && state.lastRetrieveSeminarsSuccess && state.seminars.length > 0) {
            let cacheAgeMs = (new Date()) - state.lastRetrieveSeminarsSuccess;
            if (cacheAgeMs < MAX_CACHE_AGE_MS) {
                return;
            }
        }
        try {
            result = await axios.get('/index.php/api/calendar/categories/' + id + '/' + filter + '/' + textInfo, {
                params: {
                    bo: bo ? true : false
                }
            });
        }
        catch (error) {
            commit('SET_LAST_RETRIEVE', false);
            throw error;
        }
        if (result) {
            commit('SET_LAST_RETRIEVE', true);
            commit('SET_RETRIEVE_BO', bo ? true : false);
            commit('SET_SEMINARS', result.data);
        }
    },
    async retrieveSeminar({ commit }, { id, bo }) {
        const textInfo = 1;
        const filter = 0;
        let result;
        try {
            result = await axios.get('/index.php/api/calendar/categories/' + id + '/' + filter + '/' + textInfo, {
                params: {
                    bo: bo ? true : false
                }
            });
        }
        catch (error) {
            throw error;
        }
        if (result && result.data.length > 0) {
            let seminar = result.data[0];
            commit('SET_SEMINAR', seminar);
        } else {
            throw ["malformed result", result];
        }
    },

};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
