// store BO about/structure
import router from '../../../router';
const state = {
    showErr:false,
    validationErrors:null,
    preview: false,
    person:{
        id:0,
        publish:0,
        administration_id:0,
        type:'',
    },
    itemPerson:{
        names: {   administration_id: 0, en: null, cz: null},
        person: {
            id: 0,
            email: null,
            first_name: null,
            last_name: null,
            telephone: null,
            title: null,
        }
    },
    categorieMembers:[],
    board:[],
    // orderBoard:[],
} ;
const getters = {
    preview: state => {
        return state.preview;
    },
    person: state => {
        return state.person;
    },
    itemPerson: state => {
        return state.itemPerson;
    },
    showErr: state => {
        return state.showErr;
    },
    validationErrors: state => {
        return state.validationErrors;
    },
    categorieMembers: state => {
        return state.categorieMembers;
    },
    board: state => {
        return state.board;
    },
    // orderBoard: state => {
    //     return state.orderBoard;
    // },
};
const mutations = {
    'SET_PERSON'(state,person){
        state.person = person;
    },
    'SET_PREVIEW' (state,payload) {
        state.preview = payload;
    },
    'DO_CRUD_METHOD'(state,response){
        if(response && state.person.publish ==1 ){
            router.push({name:'structure.bo'});
        }
    },
    'SET_SHOW_ERR'(state,show){
        state.showErr = show;
    },
    'SET_VALIDATION_ERROR'(state,err){
        state.validationErrors = err;
    },
    'SET_ITEM_PERSON'(state,payload){
        state.itemPerson = payload;
    },
    'SET_CATEGORIE_MEMBERS'(state,membersCategorie){
        state.categorieMembers = membersCategorie;
    },
    'SET_BOARD'(state,payload){
        state.board = payload;
    },
};
const actions = {
    setPreview: ({ commit }, preview) => {
        commit('SET_PREVIEW', preview);
    },
    setPerson:({commit}, person) => {
        commit('SET_PERSON',person);
    },
    setBoard:({ commit },boardData) => {
        commit('SET_BOARD',boardData);
     },
    async doCrudMethod({ commit }, path){
        try{
            const response = await axios.post(path,state.person);
            commit('SET_PREVIEW', true);
            commit('DO_CRUD_METHOD', response);
        } catch (error) {
            if (error.response.status == 422){
                commit('SET_SHOW_ERR',true);
                commit('SET_VALIDATION_ERROR',error.response.data.errors);
            }
            throw error;
        };
    },
    async setItemPerson({ commit }){
        let payload = {
            names: null,
            person: {
                id: null,
                email: null,
                first_name: null,
                last_name: null,
                telephone: null,
                title: null,
            }
        };
        // set function administrative
        let path = '/index.php/api/bo/about/structure/function/'+state.person.administration_id;
        if(state.person.type == 'facility'){
            path = '/index.php/api/bo/about/structure/function/facilities/'+state.person.administration_id;
        }
        try {
            const response = await axios.get(path);
            payload.names = response.data;
            commit('SET_ITEM_PERSON',payload);

        } catch (error) {

        }
        // set person data
        path = '/index.php/api/bo/about/structure/person/show/'+state.person.id;
        try {
            const response = await axios.get(path);
            payload.person = response.data;
            commit('SET_ITEM_PERSON',payload);
        } catch (error) {
            throw error;
        }
    },
    async setCategorieMembers({ commit }){
        let path = '/index.php/api/bo/about/structure/persons-in-his-category/show/'+state.person.id;
        try {
            const response = await axios.get(path);
            commit('SET_CATEGORIE_MEMBERS',response.data);

        } catch (error) {
            throw error;
        }
    },

};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
