/**
 *
 * menu GRANTS
 */
const Grants = resolve => {
    require.ensure(['../../components/grants/Grants.vue'], () => {
        resolve(require('../../components/grants/Grants.vue'));
    });
};
const GrantsList = resolve => {
    require.ensure(['../../components/grants/GrantsList.vue'], () => {
        resolve(require('../../components/grants/GrantsList.vue'));
    });
};
const GrantsListPast = resolve => {
    require.ensure(['../../components/grants/GrantsListPast.vue'], () => {
        resolve(require('../../components/grants/GrantsListPast.vue'));
    });
};
const GrantsListDepartment = resolve => {
    require.ensure(['../../components/grants/GrantsListDepartment.vue'], () => {
        resolve(require('../../components/grants/GrantsListDepartment.vue'));
    });
};
const GrantDetail = resolve => {
    require.ensure(['../../components/grants/GrantDetail.vue'], () => {
        resolve(require('../../components/grants/GrantDetail.vue'));
    });
};
export const GrantsAddresses = [{
    path: '/index.php/grants',
    name: 'grants',
    component: Grants,
    children: [
        { path: 'running/:active', name: 'grants-list', component: GrantsList },
        { path: 'past/:active', name: 'grants-list-past', component: GrantsListPast },
        { path: 'department/:id', name: 'grants-department', component: GrantsListDepartment },
        { path: 'infos/:id', name: 'infos', component: GrantDetail },
    ]
}, ];
