const state = {
     submitValue:false,
     namePath:'grants-list',
     pathParams:null,
} ;
const getters = {
   submitValue: state => {
        return state.submitValue;
    },
    namePath:state => {
        return state.namePath;
    },
    pathParams:state => {
        return state.pathParams;
    },
};
const mutations = {
    'SET_SUBMIT_VALUE' (state,payload) {
        state.submitValue = payload;
    },
    'SET_NAME_PATH' (state,payload) {
        state.namePath = payload;
    },
    SET_PATH_PARAMS(state,payload) {
        state.pathParams = payload;
    },
};
const actions = {
     setSubmitValue({ commit },submitValue){
        commit('SET_SUBMIT_VALUE',submitValue);
    },
    setNamePath({ commit },namePath){
        commit('SET_NAME_PATH',namePath);
    },
    setPathParams({ commit },pathParams){
        commit('SET_PATH_PARAMS',pathParams);
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
