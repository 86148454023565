export default [
    {
        id: 20,
        name: 'all'
    },
    {
        //article
        id: 1,
        name: 'journal paper',
    },
    {
        id: 2,
        name: 'book',
    },
    {
        id: 3,
        name: 'paper in proceedings',
    },
    {
        id: 4,
        name: 'book chapter',
    },
    {
        id: 5,
        name: 'report',
    },
    {
        id: 6,
        name: 'preprint',
    },
    {
        id: 7,
        name: 'thesis'
    },
    // {
    //     id: 8,
    //     name: 'lecture',
    // },
]
