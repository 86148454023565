const state={
    eventDetail:{
        dateAll:"",
        speaker:"",
        affiliation:"",
        place:"",
        webpage_url:"",
        details:"",
    },
};
const getters = {
    eventDetail: state => {
        return state.eventDetail;
    },
};
const mutations = {
  'SET_EVENT_DETAIL' (state, payload) {
     state.eventDetail= payload;
  },
};
const actions = {
    async setEventDetail({ commit }, id) {
        try{
            const result =  await axios.get('/index.php/api/calendar/event/'+id);
            if(result){
                const payload  = result.data;
                commit('SET_EVENT_DETAIL',payload);
            }
        }
        catch (error) {
            throw error;
        }
    },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
