function permissionToSeminar(getSeminar, permission) {
    if (permission.startsWith("seminar.edit")) {
        let regex = /seminar\.edit\.(\d+)/g;
        let seminarBits = regex.exec(permission);
        if (seminarBits && seminarBits.length == 2) {
            let seminarId = parseInt(seminarBits[1]);
            let seminar = getSeminar(seminarId);
            return seminar;
        }
    } else {
        return null;
    }
}

function disableIfDisabledSeminar(getSeminar, permission) {
    if (permission.startsWith("seminar.edit")) {
        let regex = /seminar\.edit\.(\d+)/g;
        let seminarBits = regex.exec(permission);
        if (seminarBits && seminarBits.length == 2) {
            let seminarId = parseInt(seminarBits[1]);
            let seminar = getSeminar(seminarId);
            return seminar != null;
        }
    } else {
        return true;
    }
}

function convertToPlain(html) {
    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

function getLatestDate(date1, date2) {
    if (date1 > date2) {
        return date1;
    } else {
        return date2;
    }
}

function getNextDayOfWeek(fromDate, targetDay) {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const targetDayIndex = daysOfWeek.indexOf(targetDay);

    if (targetDayIndex === -1) {
        throw new Error("Invalid day of the week.");
    }

    const latestDate = getLatestDate(fromDate, new Date());

    let daysUntilNextTargetDay = (targetDayIndex - latestDate.getDay() + 7) % 7;
    if (targetDayIndex == latestDate.getDay()) {
        daysUntilNextTargetDay += 7;
    }

    const nextTargetDay = new Date(latestDate);
    nextTargetDay.setDate(latestDate.getDate() + daysUntilNextTargetDay);

    return nextTargetDay;
}

function convertTo24HourFormat(time12Hour) {
    const [time, period] = time12Hour.split(' ');
    const [hours, minutes] = time.split(':');

    let hours24 = parseInt(hours, 10);

    if (period === 'PM' && hours24 < 12) {
      hours24 += 12;
    } else if (period === 'AM' && hours24 === 12) {
      hours24 = 0;
    }

    return `${hours24}:${minutes}`;
  }

function getDateWithoutTZInfo(timestamp) {
    const dateObj = new Date(timestamp);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const date = String(dateObj.getDate()).padStart(2, '0');
    const dateString = `${year}-${month}-${date}`;
    return dateString;
}

function getTimeRange(date, start, end) {
    var date = new Date(date).toLocaleDateString('en-gb', { weekday: "long", year: "numeric", month: "long", day: "numeric" });
    var range = date + " - " + start + " to " + end;
    var startDate = date + "T" + start + ":00";
    var endDate = date + "T" + end + ":00";
    return [range, startDate, endDate]
}

module.exports = {
    permissionToSeminar,
    disableIfDisabledSeminar,
    convertToPlain,
    getNextDayOfWeek,
    convertTo24HourFormat,
    getDateWithoutTZInfo,
    getTimeRange,
    getLatestDate,
};
