<template>
    <v-card id="sitemap" width="100%" text tile class=" white--text text-xs-center" color="#252697">   
        <ul v-if="language =='en'" class="ml-n4 nav nav-pills nav-fill justify-space-between">
                <li class="white--text">
                    <ul class="mt-3 ml-0 white--text"> PEOPLE
                        <li class="pt-1"><a href="/index.php/members/researchers" >Research fellows</a></li>
                        <li><a href="/index.php/members/postdocs" >Postdocs, Students </a></li>
                        <li><a href="/index.php/members/staff" >Staff</a></li>
                        <li><a href="/index.php/members/distinguishedVisitors" >Eduard Čech Distinguished Visitor</a></li>
                        <li><a href="/index.php/members/visitors/2020" >Visitors</a></li>
                    </ul>
                </li>
                <li class="white--text">
                    <ul class="mt-3  white--text">RESEARCH
                        <li class="pt-1"><a href="/index.php/research/publications" >Publications</a></li>
                        <li><a href="/index.php/research/lectures" >Lectures</a></li>
                        <li><a href="/index.php/research/collaboration" >Institutional collaboration</a></li>
                        <li><a href="/index.php/grants/running/1" >Grants</a></li>
                    </ul>
                </li>
                <li class="white--text">
                    <ul class="mt-3">EVENTS
                        <li class="pt-1"><a href="/index.php" >News</a></li>
                        <li><a href="/index.php/events/calendar" >Calendar</a></li>
                        <li><a href="/index.php/events/seminars" >Seminars</a></li>
                        <li><a href="/index.php/events/conferences" >Conferences, workshops</a></li>
                        <li><a href="/index.php/events/lecturesCech" >Eduard Čech Lectures</a></li>
                        <li><a href="/index.php/events/awards" >Awards</a></li>
                    </ul>
                </li>
                <li class="white--text">
                    <ul class="mt-3" >ABOUT
                        <li class="pt-1"><a href="/index.php/about/mission" >Mission</a></li>
                        <li><a href="/index.php/about/structure" >Structure Institute</a></li>
                        <li><a href="/index.php/about/reports">Annual reports</a></li>
                        <li><a href="/index.php/about/documents">Documents</a></li>
                        <li><a href="/index.php/about/contact">Contact</a></li>
                    </ul>
                </li>
                <li class="white--text">
                    <ul class="mt-3">DEPARTMENTS
                        <li class="pt-1"><a href="/index.php/department/detail/13" >Constructive Methods of Mathematical Analysis</a></li>
                        <li><a href="/index.php/department/detail/14" >Abstract Analysis</a></li>
                        <li><a href="/index.php/department/detail/15" >Evolution Differential Equations</a></li>
                        <li><a href="/index.php/department/detail/16" >Mathematical Logic and Theoretical Computer Science</a></li>
                        <li><a href="/index.php/department/detail/18" >Algebra, Geometry and Mathematical Physics</a></li>

                    </ul>
                </li>
                <li class="white--text">
                    <ul class="mt-3 mr-1">LIBRARY
                        <li class="pt-1"><a href="/index.php/library/home" >Library of the Institute</a></li>
                        <li><a href="/index.php/library/journal/1/home" >Applications of Mathematics</a></li>
                        <li><a href="/index.php/library/journal/2/home" >Czechoslovak Mathematical Journal</a></li>
                        <li><a href="/index.php/library/journal/3/home" >Mathematica Bohemica</a></li>
                        <li><a href="https://higher-structures.math.cas.cz/" >Higher Structures</a></li>
                        <li><a href="/index.php/library/zbmath/about" >zbMATH Open</a></li>
                    </ul>
                </li>
        </ul>
        <ul v-else class="ml-n4 nav nav-pills nav-fill justify-space-between">
                <li class="white--text">
                    <ul class="mt-3 white--text"> LIDÉ
                        <li class="pt-1"><router-link :to="{name:'researchers'}">Vědečtí pracovníci</router-link></li>
                        <li><router-link :to="{name:'postdocs'}">Postdoktorandi Studenti</router-link></li>
                        <li><router-link :to="{name:'staff'}">Ostatní zaměstnanci</router-link></li>
                        <li><router-link :to="{name:'distinguishedVisitors'}">Eduard Čech Distinguished Visitor</router-link></li>
                        <li><router-link :to="{name:'visitors',params:{year:2020}}">Hostující pracovníci</router-link></li>
                    </ul>
                </li>
                <li class="white--text">  
                    <ul class="mt-3  white--text">VÝZKUM
                        <li class="pt-1"><router-link :to="{name:'publicationsAll'}">Publikace</router-link></li>
                        <li><router-link :to="{name:'lecturesAll'}">Přednášky</router-link></li>
                        <li><router-link :to="{name:'collaboration'}">Spolupráce</router-link></li>
                        <li><router-link :to="{name:'grants-list'}">Granty</router-link></li>
                    </ul>
                </li>
                <li class="white--text">
                    <ul class="mt-3">VĚDECKE AKCE   
                        <li class="pt-1"><router-link :to="{name:'home'}">Aktuality</router-link></li>
                        <li><router-link :to="{name:'calendar'}">Kalendář</router-link></li>
                        <li><router-link :to="{name:'seminars'}">Seminaře</router-link></li>
                        <li><router-link :to="{name:'conferences'}">Konference, workshopy</router-link></li>
                        <li><router-link :to="{name:'lecturesCech'}">Čechovské přednášky</router-link></li>
                        <li><router-link :to="{name:'awards'}">Ocenění</router-link></li>
                    </ul>
                </li>
                <li class="white--text">
                    <ul class="mt-3" >O NÁS
                        <li  class="pt-1"><router-link :to="{name:'mission'}">Poslání</router-link></li>
                        <li><router-link :to="{name:'structure'}">Structura</router-link></li>
                        <li><router-link :to="{name:'reports'}">Výroční zprávy</router-link></li>
                        <li><router-link :to="{name:'documents'}">Dokumenty</router-link></li>
                        <li><router-link :to="{name:'contact'}">Contact</router-link></li>
                    </ul>
                </li>
                <li class="white--text">
                    <ul class="mt-3">ODDĚLENI
                        <li class="pt-1"><router-link :to="{name:'detail',params:{id:13}}">Konstruktivní metody matematické analýzy</router-link></li>
                        <li><router-link :to="{name:'detail',params:{id:14}}">Abstraktní analýza </router-link></li>
                        <li><router-link :to="{name:'detail',params:{id:15}}">Evoluční diferenciální rovnice</router-link></li>
                        <li><router-link :to="{name:'detail',params:{id:16}}">Matematická logika a teoretická informatika</router-link></li>
                        <li><router-link :to="{name:'detail',params:{id:18}}">Algebra, geometrie a matematická fyzika</router-link></li>
                    </ul>
                </li>
                <li class="white--text">
                    <ul class="mt-3 mr-1">KNIHOVNA
                        <li class="pt-1"><router-link :to="{name:'libraryins'}">Knihovna</router-link></li>
                        <li><router-link :to="{name:'journal',params:{journal_id:1,tab:'home'}}">Applications of Mathematics</router-link></li>
                        <li><router-link :to="{name:'journal',params:{journal_id:2,tab:'home'}}"></router-link><a href="/index.php/library/journal/2/home" >Czechoslovak Mathematical Journal</a></li>
                        <li><router-link :to="{name:'journal',params:{journal_id:3,tab:'home'}}"></router-link><a href="/index.php/library/journal/3/home" >Mathematica Bohemica</a></li>
                        <li><a href="https://higher-structures.math.cas.cz/" >Higher Structures</a></li>
                        <li><router-link :to="{name:'zbmath',params:{tab:'about'}}"></router-link><a href="/index.php/library/zbmath/about" >zbMATH Open</a></li>
                    </ul>
                </li>
        </ul>   
    </v-card>
   
  </template>
  <script>
  import {mapGetters} from 'vuex';
  
  export default {
    data() {
      return {
       
      }
    },
   
    computed: {
      ...mapGetters('header',{
          language:'language',
          
      }),
      
    }
  }
  </script>
  <style scoped>
    #sitemap {
            background-color:#252697; 
            color:#ffffff;
            font-size: 80%;
        }
        #sitemap a, a:hover {
            color:#ffffff
        }
</style>

  