// addresses from BD - TODO
import { dynamicExternalAddresses } from './helpers/dynamicExternalAddress.js';
//main menu
import { PeopleAddresses } from './mainMenu/peopleAddresses.js';
import { ResearchAddresses } from './mainMenu/researchAddresses.js';
import { EventsAddresses } from './mainMenu/eventsAddress.js';
import { AboutAddresses } from './mainMenu/aboutAddresses.js';
// bis menu
import { DepartmentsAddresses } from './bisMenu/departmentsAddresses.js';
import { GrantsAddresses } from './bisMenu/grantsAddresses.js';
import { LibraryAddresses } from './bisMenu/libraryAddresses.js';
// BO dashboard
import { DashboardAddresses } from './bo/dashboardAddresses.js';
//import { BoAboutAddresses }  from './bo/boAboutAddresses.js';


/**
 * menu HOME, NEWS
 */
const NewsHome = resolve => {
    require.ensure(['../components/home/NewsHome.vue'], () => {
        resolve(require('../components/home/NewsHome.vue'));
    });
};
const NewDetail = resolve => {
    require.ensure(['../components/home/NewDetail.vue'], () => {
        resolve(require('../components/home/NewDetail.vue'));
    });
};
const News = resolve => {
    require.ensure(['../components/home/News.vue'], () => {
        resolve(require('../components/home/News.vue'));
    });
};
const NewsArchive = resolve => {
  require.ensure(['../components/home/archive/NewsArchive.vue'], () => {
      resolve(require('../components/home/archive/NewsArchive.vue'));
  });
};
/**
 * menu LOGIN
 */
const UserLogin = resolve => {
    require.ensure(['../components/bo/login/UserLogin.vue'], () => {
        resolve(require('../components/bo/login/UserLogin.vue'));
    });
};
/**
 * menu EVENTS
 */
const OldEvent = resolve => {
    require.ensure(['../components/events/OldEvent.vue'], () => {
        resolve(require('../components/events/OldEvent.vue'));
    });
};
//  path: '/index.php/',  !!!!!pro realny server !!!
// name: 'home',

const addresses = [
    {
        path: '/',
        name: 'main',
        component: News,
    },
    {
        path: '/index.php/',
        name: 'home',
        component: News,
    },
    {
        path: '/index.php/user/login',
        name: 'userlogin',
        component: UserLogin,
    },
    {
        path: '/index.php/news',
        name: 'newshome',
        component: NewsHome,
        children: [
            { path: 'news', name: 'news', component: News },
            { path: 'item/:id', name: 'new', component: NewDetail },
        ]
    },
    {
        path: '/index.php/archive/:dashboard',
        name: 'archive',
        component: NewsArchive,
    },
    {
        path:'/content/:title',
        name:'oldevent',
        component:OldEvent,
    },
    {
        path: '/index.php/positions',
        name: 'positions',
        beforeEnter() { location.href = 'https://application.math.cas.cz/Positions.html' },
    },
    {
        path: '/index.php/higher',
        name: 'higher',
        beforeEnter() { location.href = 'https://higher-structures.math.cas.cz' },
    },
    {
        path: '/index.php/homepage',
        name: 'homepage',
        beforeEnter() { location.href = '/oldim/modification/menu_homepage.php' }, //ok

    },
    {
        path: '/index.php/vuedoc/',
        name: 'documentation.bo',
        beforeEnter() { location.href = '/index.php/vuedoc/' },
    },
    {
        path: "*",
        component: News
    },
];
const allRoutes = addresses.concat(
    dynamicExternalAddresses, //extern addresses from BD
    PeopleAddresses, // main menu people
    ResearchAddresses, // main menu research
    EventsAddresses, // main menu events
    AboutAddresses, // main menu about
    DepartmentsAddresses, // bis menu departments
    GrantsAddresses, // bis menu grants
    LibraryAddresses, // bis menu library
    DashboardAddresses, // bo dashboard
    //BoAboutAddresses // BO administration About menu
);

// import { resolve } from 'url';
// import { resolveCname } from 'dns';

export default allRoutes
