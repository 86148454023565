import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store/store';
import { i18n } from '../plugins/i18n/i18n.js';


Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (to.hash == "#more") {
            position.selector = to.hash;
            return false;
        }
        else
            return { x: 0, y: 0 }
    }
});
router.beforeEach((to, from, next) => {
    i18n.locale = store.state.header.language;
    // prevent to return tu first form with publication data from second form with asep data
     if ( from.name == 'asep.form' &&
            (to.name == 'publication.bo.edit' || to.name == 'publication.bo.add') ) {
        next(false);
    } else {
        next(); // allow navigation in other cases
    }
});

export default router;
