export default [
    {
        id: 20,
        name: 'vše'
    },
    {
        //article
        id: 1,
        name: 'článek',
    },
    {
        id: 2,
        name: 'kniha',
    },
    {
        id: 3,
        name: 'článek ve sborníku',
    },
    {
        id: 4,
        name: 'kapitola v knize',
    },
]
