
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    breakpoint: {
        thresholds: {
        xs: 600,
        sm: 960,
        md: 1300,
        lg: 1904
        },
    },
    theme: {
    themes: {
      light: {
        anchor: '#0d47a1',
      },
    },
  },
}

export default new Vuetify(opts)
