import router from '../../../router';

const typePublication = {
    article:1,
    book:2,
    proceedings:3,
    chapter:4,
    report:5,
    preprint:6,
    thesis:7,
    lecture:8,
};
const state = {
    type:null,
    externalAuthors:[],
    internalAuthors:[],
    externalAuthorsNames:[],
    internalAuthorsNames:[],
    publicationGrants:[],
    publicationArticle:{},
    publicationBook:{},
    publicationInProceeding:{},
    publicationChapter:{},
    publicationReport:{},
    publicationPreprint:{},
    publicationThesis:{},
    publicationLecture:{},

    publication:{
        type:20,
        externalAuthors:[],
        internalAuthors:[],
        allAuthorsNames:[],
        authors:[],
        grants:[],
        grantsList:[],
        article:{},
        book:{},
        report:{},
        chapter:{},
        inProceeding:{},
        preprint:{},
        thesis:{},
        lecture:{},
    },
    submitValue:false,
    showErr:false,
    validationErrors:null,
    publicationErr:false,
    namePath:'publication.bo',
    prevNamePath:'publication.bo',
    paramsPath:{},
    dataPublication:null,
    asep: {
        isSet: false,
        year : null, 
        dept : null, 
        author : null,
        type: null,
        status: null,
        hasValue: false
    },
    authorsChanged:false,
};
const getters = {
    type: state => {
        return state.type;
    },
    externalAuthors: state => {
        return state.externalAuthors;
    },
    internalAuthors: state => {
        return state.internalAuthors;
    },
    externalAuthorsNames: state => {
        return state.externalAuthorsNames;
    },
    internalAuthorsNames: state => {
        return state.internalAuthorsNames;
    },
    authors: state =>{
        return state.authors;
    },
    publicationGrants: state => {
        return state.publicationGrants;
    },
    submitValue: state => {
        return state.submitValue;
    },
    publication: state => {
        return state.publication;
    },
    showErr: state => {
        return state.showErr;
    },
    validationErrors: state => {
        return state.validationErrors;
    },
    publicationArticle: state => {
        return state.publicationArticle;
    },
    publicationBook:state => {
        return state.publicationBook;
    },
    publicationInProceeding:state => {
        return state.publicationInProceeding;
    },
    publicationChapter:state => {
        return state.publicationChapter;
    },
    publicationReport:state => {
        return state.publicationReport;
    },
    publicationPreprint:state => {
        return state.publicationPreprint;
    },
    publicationThesis:state => {
        return state.publicationThesis;
    },
    publicationLecture:state => {
        return state.publicationLecture;
    },
    publicationErr:state => {
        return state.publicationErr;
    },
    namePath:state => {
        return state.namePath;
    },
    prevNamePath:state => {
        return state.prevNamePath;
    },
    dataPublication:state => {
        return state.dataPublication;
    },
    asep: state => {
        return state.asep;
    },
    authorsChanged:state => {
        return state.authorsChanged;
    },
};
const mutations = {
    'INITIALIZATION'(state) {
        state.externalAuthors=[];
        state.internalAuthors=[];
        state.externalAuthorsNames=[];
        state.internalAuthorsNames=[];
        state.publicationGrants=[];
        state.publicationArticle={};
        state.publicationBook={};
        state.publicationInProceeding={};
        state.publicationChapter={};
        state.publicationReport={};
        state.publicationPreprint={};
        state.publicationThesis={};
        state.publicationLecture={};

        state.publication = {
            type:state.type,
            externalAuthors:[],
            internalAuthors:[],
            allAuthorsNames:[],
            authors:[],
            grants:[],
            grantsList:[],
            article:{},
            book:{},
            report:{},
            chapter:{},
            inProceeding:{},
            preprint:{},
            thesis:{},
            lecture:{},
        };
        state.dataPublication = {};
        state.submitValue=false;
        state.showErr=false;
        state.validationErrors=null;
        state.publicationErr=false;
        state.authorsChanged=false;
    },
    'SET_TYPE'(state,type){
        state.type = type;
    },
    'SET_EXTERNAL_AUTHORS'(state,externals){
        state.externalAuthors = externals;
        state.publication.externalAuthors= externals;
    },
    'SET_INTERNAL_AUTHORS'(state,internals){
        state.internalAuthors = internals;
        state.publication.internalAuthors= internals;
    },
    'SET_EXTERNAL_AUTHORS_NAMES'(state,names){
        state.externalAuthorsNames = names;
        state.publication.allAuthorsNames= state.internalAuthorsNames.concat(state.externalAuthorsNames).sort();
    },
    'SET_INTERNAL_AUTHORS_NAMES'(state,names){
        state.internalAuthorsNames = names;
        state.publication.allAuthorsNames= state.internalAuthorsNames.concat(state.externalAuthorsNames).sort();
    },
    'SET_AUTHORS'(state){
        state.publication.authors =[];
        state.publication.allAuthorsNames.forEach(element => {
            var author = null;
            var authors_arr = element.split('_');
            author = {
                    name_last:authors_arr[0],
                    name_first:authors_arr[1],
                    type:parseInt(authors_arr[2]),
            };
            if(author.name_last != '' && author.name_first != ''){
                state.publication.authors.push(author);
            }

        });
    },
    'SET_PUBLICATION_GRANTS'(state,publicationGrants){
        state.publicationGrants = publicationGrants;
        state.publication.grants = state.publicationGrants;
    },
    'SET_PUBLICATION_GRANTS_LIST'(state,grantsList){
        state.publication.grantsList = grantsList;
    },
    'SET_PUBLICATION_ARTICLE'(state,publicationArticle){
        state.publicationArticle = publicationArticle;
        state.publication.article = state.publicationArticle;
        state.publication.type = typePublication.article;
    },
    'SET_PUBLICATION_BOOK'(state,publicationBook){
      state.publicationBook = publicationBook;
      state.publication.book = state.publicationBook;
      state.publication.type = typePublication.book;
    },
    'SET_PUBLICATION_CHAPTER'(state,publicationChapter){
      state.publicationChapter = publicationChapter;
      state.publication.chapter = state.publicationChapter;
      state.publication.type = typePublication.chapter;
    },
    'SET_PUBLICATION_IN_PROCEEDING'(state,publicationInProceeding){
      state.publicationInProceeding = publicationInProceeding;
      state.publication.inProceeding = state.publicationInProceeding;
      state.publication.type = typePublication.proceedings;
    },
    'SET_PUBLICATION_REPORT'(state,publicationReport){
      state.publicationReport = publicationReport;
      state.publication.report = state.publicationReport;
      state.publication.type = typePublication.report;
    },
    'SET_PUBLICATION_PREPRINT'(state){
      state.publication.type = typePublication.preprint;
    },
    'SET_PUBLICATION_THESIS'(state){
      state.publication.type = typePublication.thesis;
    },
    'SET_PUBLICATION_LECTURE'(state){
      state.publication.type = typePublication.lecture;
    },
    'SET_PUBLICATION_ERR'(state,publicationErr){
      state.publicationErr = publicationErr;
    },
    'SET_SHOW_ERR'(state,show){
        state.showErr = show;
    },
    'SET_VALIDATION_ERROR'(state,err){
        state.validationErrors = err;
    },
    'SET_SUBMIT_VALUE' (state,payload) {
        state.submitValue = payload;
    },
    'SET_NAME_PATH' (state,payload) {
        state.namePath = payload;
    },
    'SET_PREV_NAME_PATH' (state,payload) {
        state.prevNamePath = payload;
    },
    'SET_PARAMS_PATH' (state,payload) {
        state.paramsPath = payload;
    },
    'SET_DATA_PUBLICATION' (state,payload) {
        state.dataPublication = payload;
    },
    'SET_ASEP' (state,payload) {
        var isSet = state.asep.isSet;
        state.asep = payload;
        // Preserve isSet
        state.asep.isSet = isSet;
    },
    'TOGGLE_ASEP' (state,payload) {
        state.asep.isSet = payload;
    },
    'SET_AUTHORS_CHANGED'(state,payload) {
        state.authorsChanged = payload;
    },
};
const actions = {
    setType: ({ commit }, type) => {
        commit('SET_TYPE',type);
    },
    initialization:({ commit }) => {
        commit('INITIALIZATION');
    },
    setExternalAuthors: ({ commit }, externals) => {
        commit('SET_EXTERNAL_AUTHORS',externals);
    },
    setInternalAuthors: ({ commit }, internals) => {
        commit('SET_INTERNAL_AUTHORS',internals);
    },
    setExternalAuthorsNames: ({ commit }, names) => {
        commit('SET_EXTERNAL_AUTHORS_NAMES',names);
        commit('SET_AUTHORS');
    },
    setInternalAuthorsNames: ({ commit }, names) => {
        commit('SET_INTERNAL_AUTHORS_NAMES',names);
        commit('SET_AUTHORS');
    },
    setPublicationGrants:({commit},publicationGrants) => {
        commit('SET_PUBLICATION_GRANTS',publicationGrants);
    },
    setPublicationGrantsList:({commit},publicationGrantsList) => {
        commit('SET_PUBLICATION_GRANTS_LIST',publicationGrantsList);
    },
    setPublicationArticle:({commit},publicationArticle) => {
        commit('SET_PUBLICATION_ARTICLE',publicationArticle);
    },
    setPublicationBook:({commit},publicationBook) => {
        commit('SET_PUBLICATION_BOOK',publicationBook);
    },
    setPublicationChapter:({commit},publicationChapter) => {
        commit('SET_PUBLICATION_CHAPTER',publicationChapter);
    },
    setPublicationInProceeding:({commit},publicationInProceeding) => {
        commit('SET_PUBLICATION_IN_PROCEEDING',publicationInProceeding);
    },
    setPublicationReport:({commit},publicationReport) => {
        commit('SET_PUBLICATION_REPORT',publicationReport);
    },
    setPublicationPreprint:({commit}) => {
        commit('SET_PUBLICATION_PREPRINT');
    },
    setPublicationThesis:({commit}) => {
        commit('SET_PUBLICATION_THESIS');
    },
    setPublicationLecture:({commit}) => {
        commit('SET_PUBLICATION_LECTURE');
    },
    async setDataPublication({commit},id){
        commit('INITIALIZATION');
        var publications_path = '/index.php/api/bo/research/publication/edit/'+id;
        await axios.get(publications_path)
            .then(({data})=>{
                commit('SET_DATA_PUBLICATION',data);
            }).catch((response)=>{
                if(response.status == 401){
                    router.push({name:'userlogin'})
                }
            })
            .catch(function (error) {
                console.log(error.toJSON());
            });
    },

    async setPublication({ commit },id){
        let  urlSwitchAsep = null;
        let path = '/index.php/api/bo/research/publication/';
        if(id > 0 ){
            path = path+'update/'+id
        }
        else{
            state.authorsChanged = true;
            path = path+'add';
        }

        var publication = {'type':state.publication.type,
            'authors':state.publication.authors,
            'grants' : state.publication.grants,
        };
        // flag authorsChanged checks le changement dans forms internals and external authors
        // without changing the publication we must keeps backup data to avoid to lose order authors
        if(state.authorsChanged == false){
            publication.authors = state.dataPublication.authors;
        }
        if(state.publication.type == 1){
            publication.article = state.publication.article;
            if(!publication.article.url){
                urlSwitchAsep = true;
            }
        }
        else if(state.publication.type == 2){
            publication.book = state.publication.book;
            if(!publication.book.url){
                urlSwitchAsep = true;
            }
        }
        else if(state.publication.type == 3){
          publication.inProceeding = state.publication.inProceeding;
          if(!publication.inProceeding.url){
                urlSwitchAsep = true;
            }
        }
        else if(state.publication.type == 4){
          publication.chapter = state.publication.chapter;
          if(!publication.chapter.url){
                urlSwitchAsep = true;
            }
        }
        else if(state.publication.type == 5){
          publication.report = state.publication.report;
        }
        else if(state.publication.type == 6){
          publication.preprint = state.publication.preprint;
        }
        else if(state.publication.type == 8){
          publication.thesis = state.publication.thesis;
        }
        else{
            publication.lecture = state.publication.lecture;
        }

        try{
            const response = await axios.post(path,publication);
            if(response.data > 0){
                if(!(id > 0)){
                    id= response.data;
                }
                if(state.namePath == 'authors.bo.modify') {
                    router.push({name:state.namePath, params:{id:id}});
                }
                else if(urlSwitchAsep){
                    router.push({name:'asep.form', params:{asep:0,type:state.publication.type,id:id}});
                }
                else {
                     router.push({name:state.namePath});
                }
            }
            else{
                commit('SET_SHOW_ERR',true);
                commit('SET_PUBLICATION_ERR',response.data);
            }
        } catch (error) {
            if (error.response.status == 422){
                commit('SET_SHOW_ERR',true);
                commit('SET_VALIDATION_ERROR',error.response.data.errors);
            }
            else{
                commit('SET_PUBLICATION_ERR',error.response.data.errors);
            }
            throw error;
        };
    },
    setPublicationErr({ commit },stateErr){
        commit('SET_PUBLICATION_ERR',stateErr);
        commit('SET_SUBMIT_VALUE',!stateErr);
    },
    setSubmitValue({ commit },submitValue){
        commit('SET_SUBMIT_VALUE',submitValue);
    },
    setNamePath({ commit },namePath){
        commit('SET_NAME_PATH',namePath);
    },
    setPrevNamePath({ commit },namePath){
        commit('SET_PREV_NAME_PATH',namePath);
    },
    activateAsepState({ commit }) {
        commit('TOGGLE_ASEP', true);
    },
    deactivateAsepState({ commit }) {
        commit('TOGGLE_ASEP', false);
    },
    setAsepState({ commit }, [year, dept, author, type, status]){
        commit('SET_ASEP', {
            year : year, 
            dept : dept, 
            author : author,
            type: type,
            status: status,
            hasValue: true
        });
    },
    resetAsepState({ commit }){
        commit('SET_ASEP', {hasValue: false,
            year : null, 
            dept : null, 
            author : null,
            type: null,
            status: null});
    },
    setAuthorsChanged({ commit },payload){
        commit('SET_AUTHORS_CHANGED',payload);
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
