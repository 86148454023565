// store BO about/documents

const state = {
    documentsCz:[],
    documentsEn:[],
} ;
const getters = {
    documentsCz: state => {
        return state.documentsCz;
    },
    documentsEn: state => {
        return state.documentsEn;
    },
};
const mutations = {
    'SET_DOCUMENTS_CZ'(state,documents){
        state.documentsCz = documents;
    },
    'SET_DOCUMENTS_EN'(state,documents){
        state.documentsEn = documents;
    },
};
const actions = {
    setDocumentsCz: ({ commit }, documents) => {
        commit('SET_DOCUMENTS_CZ',documents);
    },
    setDocumentsEn: ({ commit }, documents) => {
        commit('SET_DOCUMENTS_EN',documents);
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
