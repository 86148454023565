<template>
  <v-footer v-if="currentRouteName!='monitoring'"
    height="auto"
    color="#252697"
    class="pa-0 mx-0"
  >

    <v-card width="100%" text tile class=" white--text text-xs-center" color="#252697">
      <v-card-text class=" white--text text-center my-0">
        <v-row wrap class="my-0">
            <v-col cols="12" md="1" lg="1" xl="1" >
                <app-soc-network></app-soc-network>
            </v-col>
            <v-col  cols="12" md="11" lg="11" xl="11">
                <!-- largre -->
                <v-row class="hidden-sm-and-down mx-0 mb-5" justify="space-around">
                    <a href="https://www.avcr.cz/en/"  target="_blank">
                        <v-img
                            :src="require('../../assets/footer/'+footerCASimage)"
                            alt="Akademie věd České republiky"
                            width="15em"
                            position="right 60%"
                            class="mt-2 mb-3"
                            contain>
                        </v-img>
                    </a>
                    <a href="https://www.math.cas.cz/index.php/about/HRExcellence"  target="_blank">
                        <v-img
                            :src="require('../../assets/footer/logo_strategy_hr_0_transparent.png')"
                            alt="HR Excellence"
                            width="4em"
                            position="right 60%"
                            class="mt-2 mb-3"
                            contain>
                        </v-img>
                    </a>
                    <a :href="'https://jcmf.cz/?q='+language" target="_blank">
                        <v-img
                            :src="require('../../assets/footer/'+footerCMSimage)"
                            alt="The Union of Czech Mathematicians and Physicists"
                            width="9em"
                            class="mt-0 mb-0 ml-0 "
                            contain>
                        </v-img>
                    </a>
                    <a href="https://euromathsoc.org/"  target="_blank">
                        <v-img
                            :src="require('../../assets/footer/logo_EMS_negativ.png')"
                            alt="European Mathematical Society"
                            width="12em"
                            contain>
                        </v-img>
                    </a>
                    <a href="http://www.eu-maths-in.cz/"  target="_blank">
                        <v-img
                            :src="require('../../assets/footer/logo-eumathsin-vert_transp.png')"
                            alt="Czech Network for Mathematics in Industry"
                            width="8em"
                            class="mt-1 mb-2 ml-5"
                            contain>
                        </v-img>
                    </a>
                </v-row>
                <!-- small -->
                <v-row class="hidden-md-and-up" justify="center">
                    <a href="https://www.avcr.cz/en/"  target="_blank" >
                        <v-img
                            :src="require('../../assets/footer/'+footerCASimage)"
                            alt="Akademie věd České republiky"
                            width="15em"
                            position="50% 50%"
                            class="mt-4 mb-3"
                            contain>
                        </v-img>
                    </a>
                </v-row>
                <v-row class="hidden-md-and-up" justify="center">
                    <a href="https://www.math.cas.cz/index.php/about/HRExcellence"  target="_blank">
                        <v-img
                            :src="require('../../assets/footer/logo_strategy_hr_0_transparent.png')"
                            alt="HR Excellence"
                            width="4em"
                            position="right 60%"
                            class="mt-2 mb-3"
                            contain>
                        </v-img>
                    </a>
                </v-row>    
                <v-row class="hidden-md-and-up" justify="center">
                    <a :href="'https://jcmf.cz/?q='+language" target="_blank">
                    <v-img
                        :src="require('../../assets/footer/'+footerCMSimage)"
                        alt="The Union of Czech Mathematicians and Physicists=="
                        width="9em"
                        class="mt-2 mb-2 ml-0 "
                        contain>
                    </v-img>
                    </a>
                </v-row>
                <v-row class="hidden-md-and-up my-2" justify="center">
                        <a href="https://euromathsoc.org/"  target="_blank">
                        <v-img
                            :src="require('../../assets/footer/logo_EMS_negativ.png')"
                            alt="European Mathematical Society"
                            width="12em"
                            class="mx-2 my-2"
                            contain>
                        </v-img>
                        </a>
                </v-row>
                <v-row class="hidden-md-and-up" justify="center">
                    <a href="http://www.eu-maths-in.cz/"  target="_blank">
                    <v-img
                        :src="require('../../assets/footer/logo-eumathsin-vert_transp.png')"
                        alt="Czech Network for Mathematics in Industry"
                        width="8em"
                        class="mt-2 mb-2"
                        contain>
                    </v-img>
                    </a>
                </v-row>

                <v-row  class="mt-6">
                    <v-col cols="12" md="11" class="hidden-md-and-up mb-0 pt-0 pb-1">
                        {{ footerIntitute }}
                    </v-col>
                    <v-col  cols="12" md="11"   class="hidden-md-and-up mb-0 pt-0 pb-1">
                        Žitná 25,
                    </v-col>
                    <v-col cols="12" md="11"  class="hidden-md-and-up mb-0 pt-0 pb-1">
                        110 00   Praha 1,
                    </v-col>
                    <v-col  cols="12" md="11"  class="hidden-md-and-up mb-0 pt-0 pb-1" >
                        {{ footerRep }}
                    </v-col>
                    <v-col cols="12" md="11"   class="hidden-md-and-up mb-0 pt-0 pb-1">
                        Tel. +420 222 090 702
                    </v-col>
                    <v-col   cols="12" md="11"   class="hidden-md-and-up mb-0 py-0 ">
                        E-mail mathinst@math.cas.cz
                    </v-col>
                    <v-col  cols="12" class="text-center mt-1 hidden-sm-and-down mb-0 pa-0">
                        {{ footerIntitute }},&nbsp;&nbsp;
                        Žitná 25,&nbsp;&nbsp;  110 00&nbsp;&nbsp; Praha 1,&nbsp;&nbsp; {{ footerRep }}
                    </v-col>
                    <v-col cols="12" class="text-center hidden-sm-and-down mt-0 mt-md-1 mb-0 pa-0 ">
                        Tel. +420 222 090 702 &nbsp;&nbsp;E-mail mathinst@math.cas.cz
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <site-map></site-map>
   
  </v-footer>
</template>
<script>
import {mapGetters} from 'vuex';
import socNetwork from './SocNetwork.vue';
import siteMap from './Sitemap.vue';
export default {
  data() {
    return {
      xsOnly:false,
    }
  },
  monted(){
    this.xsOnly = this.$vuetify.breakpoint.xsOnly;
  },
  components:{
    AppSocNetwork:socNetwork,
    SiteMap:siteMap,
  },
  computed: {
    ...mapGetters('header',{
        language:'language',
        footerCASimage:'footerCASimage',
        footerCMSimage:'footerCMSimage',
        footerIntitute:'footerIntitute',
        footerRep:'footerRep',
    }),
    currentRouteName() {
            return this.$route.name;
    },
  }
}
</script>
<style scoped>

</style>
