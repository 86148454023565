import axios from 'axios';

const state = {
    selectedYear: null,
    visitors:[],
    visitorsLetter:[],
    messageVisitorsLetter:false,
};
const getters = {
    selectedYear: state => {
        return state.selectedYear;
    },
    visitors: state => {
      return state.visitors;
    },
    visitorsLetter: state => {
      return state.visitorsLetter;
    },
    messageVisitorsLetter: state => {
      return state.messageVisitorsLetter;
    },
};
const mutations = {
    'SET_VISITORS' (state,visitors ) {
      state.visitors = visitors;
    },
    'SET_VISITORS_YEAR' (state, payload ) {
      state.selectedYear = payload.year;
      state.visitors = payload.visitorsData ;
    },
    'SET_VISITORS_LETTER' (state, payload ) {
      state.visitorsLetter = payload.visitorsLetter ;
    },
    'SET_MESSAGE_VISITORS_LETTER' (state, payload ) {
      state.messageVisitorsLetter = payload ;
    },
};
const actions = {
    async setVisitorsYear({ commit }, year){
        const response = await axios.get(
            '/index.php/api/visitors/'+year
        );
       const payload = {'year': year, 'visitorsData': response.data};
       commit('SET_VISITORS_YEAR',payload);
    },
    async setVisitorsLetter({ commit }, letter){
        const response = await axios.get(
            '/index.php/api/bo/people/visitors/stays/'+letter
        );
       const payload = { 'visitorsLetter': response.data};
       if(response.data.length == 0){
            commit('SET_MESSAGE_VISITORS_LETTER',true);
       }
       else{
            commit('SET_MESSAGE_VISITORS_LETTER',false);
       }
       commit('SET_VISITORS_LETTER',payload);
    }

};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
