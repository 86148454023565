const MAX_CACHE_AGE_MS = 1000 * 60 * 3; // 3h
import { convertToPlain } from '../../components/bo/helpers';

const state = {
    id: 0,
    nb_grants: 0,
    nb_publications: 0,
    departments: {},
    researchers: {},
    retrieveDepartmentsFailed: false,
    lastRetrieveDepartmentsSuccess: null,
    retrieveResearchersFailed: {},
    lastRetrieveResearchersSuccess: {},

};
const getters = {
    id: state => {
        return state.id;
    },
    nb_grants: state => {
        return state.nb_grants;
    },
    nb_publications: state => {
        return state.nb_publications;
    },
    getDepartments: state => {
        return state.departments;
    },
    getLastRetrieveDepartmentsSuccess: state => {
        return state.lastRetrieveDepartmentsSuccess;
    },
    getResearchers: (state) => (category) => {
        return state.researchers[category];
    },
    getResearchersAsArray: (state) => (category) => {
        return Array.from(state.researchers[category]).map(res => res[1]);
    },
    getLastRetrieveResearchersSuccess: (state) => (category) => {
        if (!(category in state.lastRetrieveResearchersSuccess)) {
            return null;
        }
        return state.lastRetrieveResearchersSuccess[category];
    },

};
const mutations = {
    'SET_ID' (state, id) {
        state.id = id;
    },
    'SET_NB_GRANTS' (state, nb_grants) {
        state.nb_grants = nb_grants;
    },
    'SET_NB_PUBLICATIONS' (state, nb_publications) {
        state.nb_publications = nb_publications;
    },
    'SET_LAST_RETRIEVE'(state, success) {
        if (success) {
            state.lastRetrieveDepartmentsSuccess = new Date();
            state.retrieveDepartmentsFailed = false;
        } else {
            state.retrieveDepartmentsFailed = true;
        }
    },
    'SET_DEPARTMENTS'(state, departments) {
        state.departments = new Map(departments.map(dep => [dep.id, dep]));
    },
    'SET_LAST_RETRIEVE_RESEARCHERS'(state, { success, category }) {
        if (success) {
            state.lastRetrieveResearchersSuccess[category] = new Date();
            state.retrieveResearchersFailed[category] = false;
        } else {
            state.retrieveResearchersFailed[category] = true;
        }
    },
    'SET_RESEARCHERS'(state, { researchers, category }) {
        // Convert to map and sanitize researcher names
        state.researchers[category] = new Map(Object.values(researchers).map(res => [parseInt(res.id), {...res, first_name: convertToPlain(res.first_name), last_name: convertToPlain(res.last_name), id:parseInt(res.id)}]));
    },
    'SET_RESEARCHER'(state, researcher) {
        state.researchers[researcher.category_id].set(researcher.id, researcher);
    },
    'DELETE_RESEARCHER'(state, researcher) {
        state.researchers[researcher.category_id].delete(researcher.id);
    },

};
const actions = {
    setId: ({ commit }, id) => {
        commit('SET_ID', id);
    },
    setNbGrants: ({ commit }, nb_grants, ) => {
        commit('SET_NB_GRANTS', nb_grants);
    },
    setNbPublications: ({ commit }, nb_publications) => {
        commit('SET_NB_PUBLICATIONS', nb_publications)
    },
    async retrieveDepartments({ commit, state }, force) {
        let result;

        // Do nothing if cache is present and recent.
        if (!force && !state.retrieveDepartmentsFailed && state.lastRetrieveDepartmentsSuccess && state.departments.length > 0) {
            let cacheAgeMs = (new Date()) - state.lastRetrieveDepartmentsSuccess;
            if (cacheAgeMs < MAX_CACHE_AGE_MS) {
                return;
            }
        }
        try {
            result = await axios.get('/index.php/api/departments/list/');
        }
        catch (error) {
            commit('SET_LAST_RETRIEVE', false);
            throw error;
        }
        if (result) {
            commit('SET_LAST_RETRIEVE', true);
            commit('SET_DEPARTMENTS', result.data);
        }
    },
    async retrieveResearchers({ commit, state }, { category, force = false }) {
        let result;

        // Do nothing if cache is present and recent.
        if (!force && !state.retrieveResearchersFailed[category] && state.lastRetrieveResearchersSuccess[category] && state.researchers[category].length > 0) {
            let cacheAgeMs = (new Date()) - state.lastRetrieveResearchersSuccess[category];
            if (cacheAgeMs < MAX_CACHE_AGE_MS) {
                return;
            }
        }
        try {
            result = await axios.get('/index.php/api/members/' + category);
        }
        catch (error) {
            commit('SET_LAST_RETRIEVE_RESEARCHERS', {success: false, category: category});
            throw error;
        }
        if (result) {
            commit('SET_LAST_RETRIEVE_RESEARCHERS', {success: true, category: category});
            commit('SET_RESEARCHERS', { researchers : result.data, category : category });
        }
    },

};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
