/**
 * conferences and seminars components
 */

const state = {
    year: 2100,
    cancelYear:0,
    cancelTalk:0,
    years: [],
    talks: [],
    upcomming: 0,
    category_id: 0,
};
const getters = {
    year: state => {
        return state.year;
    },
    cancelYear: state => {
        return state.cancelYear;
    },
    cancelTalk: state => {
        return state.cancelTalk;
    },
    years: state => {
        return state.years;
    },
    talks: state => {
        return state.talks;
    },
    upcomming: state => {
        return state.upcomming;
    },
    category_id: state => {
        return state.category_id;
    },
    talk: (state) => (id) => {
        return state.talks.find(item => item.id == id);
    }
};
const mutations = {
    'SET_TALKS_YEAR'(state, payload) {
        state.year = payload.year;
        state.talks = payload.talks;
        state.talks.forEach(talk => {
            if (talk.text) {
                talk.text = talk.text.replace(/<div/g, "<p").replace(/<\/div>/g,"</p>");
            }
            if (talk.file_url == null) {
                talk.file_url = "";
            }
        });
    },
    'SET_UPCOMMING'(state, payload) {
        state.upcomming = payload;
    },
    'FULFILL_YEAR_SELECT'(state, payload) {
        state.year = payload.lastYear;
        state.years = payload.options;
        state.upcomming = payload.upcomming;
    },
    'SET_CATEGORT_ID'(state, id) {
        state.category_id = id;
    },
    'SET_TALK'(state, { id, talk }) {
        let idx = state.talks.findIndex(item => item.id == id);
        if (talk.generaltable) {
            // Format needs to be converted.
            for (const [key, value] of Object.entries(talk.generaltable)) {
                if(key == "id") {
                    continue;
                }
                if(key == "details") {
                    talk["text"] = value;
                } else {
                    talk[key] = value;
                }
            }
            talk.text = talk.text.replace(/<div/g, "<p").replace(/<\/div>/g,"</p>");
            if (talk.file_url == null) {
                talk.file_url = "";
            }
            delete talk.generaltable;
            delete talk.generaltable_id;
        }
        if (idx == -1) {
            state.talks.push(talk);
        } else {
            state.talks[idx] = talk;
        }
    },
    'SET_CANCEL_YEAR'(state,year ) {
        state.cancelYear=year;
    },
    'SET_CANCEL_TALK'(state,payload ) {
        state.cancelTalk=payload;
    },
    'DELETE_TALK'(state, id ) {
        state.talks = state.talks.filter(talk => talk.id != id);
    }

};
const actions = {
    setYear(y) {
        state.year = y;
    },
    setCancelTalk({ commit }, payload){
        commit('SET_CANCEL_TALK', payload);
    },
    setCancelYear({ commit },y){
        commit('SET_CANCEL_YEAR', y);
    },
    setCategoryId({ commit }, id) {
        commit('SET_CATEGORT_ID', id);
    },
    async retrieveTalk({ commit, state }, id, force = false) {
        let talk = state.talks.find(item => item.id === id);
        let result;
        if (!talk || force) {
            try {
                result = await axios.get('/index.php/api/events/event/' + id);
            }
            catch (error) {
                return;
            }
            commit('SET_TALK', { id: id, talk: result.data });
        }
    },
    async setTalksYear({ commit, state }, [year, initialisation]) {
        // if(state.cancelTalk){
        //     year = state.cancelYear;
        // }
        try {
            const result = await axios.get('/index.php/api/events/talks/' + state.category_id + '/' + year + '/' + initialisation);
            if (result) {
                let talks = result.data;
                talks.forEach(v => {v.categories_event_id = parseInt(state.category_id);});
                const payload = { talks: talks, year: year };
                commit('SET_TALKS_YEAR', payload);
            }
        }
        catch (error) {
            throw error;
        }
    },
    async fulfillYearsSelect({ commit }, category_id) {
        try {
            const res = await axios.get('/index.php/api/events/categories/' + category_id);
            const options = [];
            const yyyy = new Date().getFullYear();
            if (res) {
                var categoryData = res.data;
                if (categoryData.upcomming == 2100) {
                    options.push({ value: 2100, label: 'upcoming' });
                }
                for (let i = yyyy; i >= categoryData.startYear; i--) {
                    options.push({ value: i, label: i.toString() });
                }
                options.push({ value: 0, label: 'all' });

                const payload = { options: options, lastYear: categoryData.lastYear, upcomming: categoryData.upcomming };
                commit('FULFILL_YEAR_SELECT', payload);
            }
        } catch (error) {
            throw error;
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
