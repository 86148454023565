import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);
export const i18n = new VueI18n({
    messages: {
        en: {
            home: {
                title: 'Home',
            },
            staff: {
                title: 'Staff'
            },
            summary: {
                title: 'Summary',
            },
            news: {
                title: 'News',
                more:'More news',
                archive:'News archive',
                archivetitle:'News archive',
                evens: 'Forthcoming events',
                calendar:'Calendar',
            },
            about:{
              departments:'Departments',
              mission:'Mission',
              contact:'Contact',
              information:'Information for Visitors',
              hrexcellence:'For the details see',
            },
            reports: {
                more:'More',
                less:'Less',
            },
            structure:{
              administration:'Management',
              boardint:"International Advisory Board",
              board:"Board of the Institute",
              supervisory:"Supervisory Board of the Institute",
              members:"Members",
              internal:"Internal members",
              external:"External members",
              heads:"Scientific departments",
              head: "Head",
              facilities:"Facilities",
            },
            researchers: {
                title: 'Research fellows',
                departement: 'Department',
                interests: 'Research interests',
            },
            postdocs: {
                title: 'Postdocs',
                titlebis:'Students',
                departement: 'Department',
                interests: 'Research interests',
                university: 'University',
                supervisor: 'Supervisor(s)',
            },
            researcher: {
                about: 'About me',
                interests: 'Research interests',
                department: 'Department of ',
                telephone: 'Telephone',
                office: 'Office',
            },
            visitors: {
                distinguished: 'Eduard Čech Distinguished Visitor',
                visitors: 'Visitors',
            },
            research:{
              collaboration:'Institutional collaboration',
            },
            department: {
                name: 'Department',
                head: 'Head',
                themes: 'Research topics',
                production: 'Scientifique production',
                members: 'Members',
                membersFormer: 'Former Members',
                archiv: 'Archive of departments',
                visitors: 'Current visitors',
            },
            production: {
                preprints: 'Preprints',
                publications: 'Publications',
                lectures: 'Lectures',
                theses: 'Theses',
                grants: 'Grants',
                publicationsGrants:'Publications on grants',
                all:'All publications',
            },
            grants: {
                0: 'Past grants',
                1: 'Running grants',
                less:'Less',
                more:'More',
                since:'Since',
            },
            buttongrants: {
                0: 'Past',
                1: 'Running',
            },
            documents: {
                title: 'Documents',
                report: 'Annual reports',
                pastreport:'Past reports',
            },
            events: {
                calendar: 'Calendar',
                seminars: 'Seminars',
                conferences: 'Conferences, workshops',
                cechLecture: 'Eduard Čech Lectures',
                awards: 'Awards',
                bestPapers: 'Best papers in journals published by the Institute',
            },
            library:{
                title:'Library',
                newbooks:'New books in the Library',
                newbookshort:'New books',
                eresource:'Electronic sources',
                rules:'Library rules',
                catalogue:'On-line catalogue',
                hours:'Hours and contacts',
                address:'Address',
            }
        },
        cz: {
            home: {
                title: 'MÚ AV ČR',
            },
            staff: {
                title: 'Ostatní zaměstnanci'
            },
            summary: {
                title: 'Seznam',
            },
            news: {
                title: 'Aktuality',
                more:'Další aktuality',
                archivetitle:'Archiv',
                archive:'Přejít na Archiv',
                evens: 'Chystané akce',
                calendar:'Kalendář',
            },
            about:{
              departements:'Vědecká oddělení',
              mission:'Poslání',
              contact:'Kontakt',
              information:'Informace pro hostující pracovníky',
              hrexcellence:'Více informací viz',
            },
            reports: {
                more:'Další',
                less:'Méně',
            },
            structure:{
              administration:'Vedení ústavu',
              boardint:"Mezinárodní poradní sbor",
              board:"Rada pracoviště ",
              supervisory:"Dozorčí rada",
              members:"Členové",
              internal:"Členové MÚ",
              external:"Externí členové",
              heads:"Vědecká oddělení",
              head: "Vedoucí",
              facilities:"Technická a administrativní podpora",
            },
            researchers: {
                title: 'Vědečtí pracovníci',
                departement: 'Vědecké oddělení',
                interests: 'Témata výzkumu'
            },
            postdocs: {
                title: 'Postdoktorandi',
                titlebis: 'Studenti',
                departement: 'Vědecké oddělení',
                interests: 'Témata výzkumu',
                university: 'Universita',
                supervisor: 'Školitel(é)',
            },
            researcher: {
                about: 'Kdo jsem',
                interests: 'Vědecké zájmy',
                department: 'Oddělení ',
                telephone: 'Telefon',
                office: 'Pracovna',
            },
            visitors: {
                distinguished: 'Eduard Čech Distinguished Visitor',
                visitors: 'Hostující pracovníci',
            },
            research:{
              collaboration:'Spolupráce',
            },
            department: {
                name: 'Oddělení',
                head: 'Vedoucí oddělení',
                themes: 'Vědecká témata',
                production: 'Výzkumná činnost',
                members: 'Členové',
                membersFormer: 'Bývalí členové',
                archiv: 'Archiv vědeckých oddělení',
                visitors: 'Hostující pracovníci',
            },
            production: {
                preprints: 'Preprinty',
                publications: 'Publikace',
                lectures: 'Přednášky',
                theses: 'Doktorské disertace',
                grants: 'Granty',
                publicationsGrants:'Publikace na grantech',
                more:'Další',
                less:'Méne',
                all:'Všechny publikace',
            },
            grants: {
                0: 'Ukončené granty',
                1: 'Probíhající granty',
                less:'Pouze nejnovější',
                more:'Více grantů',
                since:'Od roku',
            },
            buttongrants: {
                0: 'Ukončené',
                1: 'Probíhající',
            },
            documents: {
                title: 'Dokumenty',
                report: 'Výroční zprávy',
                pastreport:'Archiv zpráv',
            },
            events: {
                calendar: 'Kalendář akcí',
                seminars: 'Semináře',
                conferences: 'Konference, workshopy',
                cechLecture: 'Čechovské přednášky',
                awards: 'Ocenění',
                bestPapers: 'Nejlepší ċlánky v ċasopisech vydávaných v MÚ',
            },
            library:{
                title:'Knihovna',
                newbooks:'Knižní novinky knihovny',
                newbookshort:'Knižní novinky',
                eresource:'Elektronické zdroje',
                rules:'Knihovní řád',
                catalogue:'Online katalog',
                hours:'Otevírací doba a kontakty',
                address:'Adresa',
            }
        }
    }
}
)
