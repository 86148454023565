/**
 *
 * menu EVENTS
 */
const Events = resolve => {
    require.ensure(['../../components/events/Events.vue'], () => {
        resolve(require('../../components/events/Events.vue'));
    });
};
const Monitoring = resolve => {
    require.ensure(['../../components/news_monitoring/Monitoring.vue'], () => {
        resolve(require('../../components/news_monitoring/Monitoring.vue'));
    });
};
const EventsCalendar = resolve => {
    require.ensure(['../../components/events/calendar/EventsCalendar.vue'], () => {
        resolve(require('../../components/events/calendar/EventsCalendar.vue'));
    });
};
const Seminars = resolve => {
    require.ensure(['../../components/events/seminars/Seminars.vue'], () => {
        resolve(require('../../components/events/seminars/Seminars.vue'));
    });
};
const Conferences = resolve => {
    require.ensure(['../../components/events/conferences/Conferences.vue'], () => {
        resolve(require('../../components/events/conferences/Conferences.vue'));
    });
};
const LecturesCech = resolve => {
    require.ensure(['../../components/events/lecturesCech/LecturesCech.vue'], () => {
        resolve(require('../../components/events/lecturesCech/LecturesCech.vue'));
    });
};
const EventWhole= resolve => {
    require.ensure(['../../components/events/EventWhole.vue'], () => {
        resolve(require('../../components/events/EventWhole.vue'));
    });
};

const Awards = resolve => {
    require.ensure(['../../components/events/awards/Awards.vue'], () => {
        resolve(require('../../components/events/awards/Awards.vue'));
    });
};
const Seminar = resolve => {
    require.ensure(['../../components/events/seminars/Seminar.vue'], () => {
        resolve(require('../../components/events/seminars/Seminar.vue'));
    });
};
const Testseminar = resolve => {
    require.ensure(['../../components//bo/events/Testseminar.vue'], () => {
        resolve(require('../../components//bo/events/Testseminar.vue'));
    });
};
export const EventsAddresses = [{
    path: '/index.php/events',
    name: 'events',
    component: Events,
    children: [
        { path: 'calendar', name: 'calendar', component: EventsCalendar },
        { path: 'seminars', name: 'seminars', component: Seminars },
        { path: 'conferences', name: 'conferences', component: Conferences },
        { path: 'lecturesCech', name: 'lecturesCech', component: LecturesCech },
        { path: 'awards', name: 'awards', component: Awards },
        { path: 'seminar/:id', name: 'seminar', component: Seminar },
        { path: 'event/:id', name: 'event', component: EventWhole},
        { path: 'monitoring', name: 'monitoring', component: Monitoring},
        { path: 'testseminar', name: 'testseminar', component: Testseminar }
    ]
}];
