// store BO about/mission
const state = {
    enMission:{
        text:null,
    },
    czMission:{
        text:null,
    },
} ;
const getters = {
    enMission: state => {
        return state.enMission;
    },
    czMission: state => {
        return state.czMission;
    },
};
const mutations = {
    'SET_EN_MISSION'(state,enMission){
        state.enMission = enMission;
    },
    'SET_CZ_MISSION'(state,czMission){
        state.czMission = czMission;
    },
};
const actions = {
    setEnMission: ({ commit }, enMission) => {
        commit('SET_EN_MISSION', enMission);
    },
    setCzMission: ({ commit }, czMission) => {
        commit('SET_CZ_MISSION', czMission);
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
