// store BO research/cooperation
const state = {
    enItem:{
        id:0,
        title:null,
        title_url:null,
        text:null,
    },
    czItem:{
        id:0,
        title:null,
        title_url:null,
        text:null,
    },
} ;
const getters = {
    enItem: state => {
        return state.enItem;
    },
    czItem: state => {
        return state.czItem;
    },
};
const mutations = {
    'SET_EN_ITEM'(state,enItem){
        state.enItem = enItem;
    },
    'SET_CZ_ITEM'(state,czItem){
        state.czItem = czItem;
    },
};
const actions = {
    setEnItem: ({ commit }, enItem) => {
        commit('SET_EN_ITEM', enItem);
    },
    setCzItem: ({ commit }, czItem) => {
        commit('SET_CZ_ITEM', czItem);
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
