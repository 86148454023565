// store BO about/hrexcellence
const state = {
    hrEn:{
        text:null,
    },
    hrCz:{
        text:null,
    },
} ;
const getters = {
    hrEn: state => {
        return state.hrEn;
    },
    hrCz: state => {
        return state.hrCz;
    },
};
const mutations = {
    'SET_EN_HR'(state,hrEn){
        state.hrEn = hrEn;
    },
    'SET_CZ_HR'(state,hrCz){
        state.hrCz = hrCz;
    },
};
const actions = {
    setEnHr: ({ commit }, hrEn) => {
        commit('SET_EN_HR', hrEn);
    },
    setCzHr: ({ commit }, hrCz) => {
        commit('SET_CZ_HR', hrCz);
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
