// store BO about/annual reports

const state = {
    reportsCz:[],
    reportsEn:[],
} ;
const getters = {
    reportsCz: state => {
        return state.reportsCz;
    },
    reportsEn: state => {
        return state.reportsEn;
    },
};
const mutations = {
    'SET_REPORTS_CZ'(state,reports){
        state.reportsCz = reports;
    },
    'SET_REPORTS_EN'(state,reports){
        state.reportsEn = reports;
    },
};
const actions = {
    setReportsCz: ({ commit }, reports) => {
        commit('SET_REPORTS_CZ',reports);
    },
    setReportsEn: ({ commit }, reports) => {
        commit('SET_REPORTS_EN',reports);
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
