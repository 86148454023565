export default [
    {
        id: 20,
        name: 'all'
    },
    {
        //article
        id: 1,
        name: 'journal paper',
    },
    {
        id: 2,
        name: 'book',
    },
    {
        id: 3,
        name: 'paper in proceedings',
    },
    {
        id: 4,
        name: 'book chapter',
    }
]
