/**
 *
 * menu RESEARCH
 */
const Research = resolve => {
    require.ensure(['../../components/research/Research.vue'], () => {
        resolve(require('../../components/research/Research.vue'));
    });
};
const Publications = resolve => {
    require.ensure(['../../components/research/publications/Publications.vue'], () => {
        resolve(require('../../components/research/publications/Publications.vue'));
    });
};
const Grants = resolve => {
    require.ensure(['../../components/research/publications/grants/Grants.vue'], () => {
        resolve(require('../../components/research/publications/grants/Grants.vue'));
    });
};
const Lectures = resolve => {
    require.ensure(['../../components/research/publications/lectures/Lectures.vue'], () => {
        resolve(require('../../components/research/publications/lectures/Lectures.vue'));
    });
};
const Collaboration = resolve => {
    require.ensure(['../../components/research/collaboration/Collaboration.vue'], () => {
        resolve(require('../../components/research/collaboration/Collaboration.vue'));
    });
}
export const ResearchAddresses = [{
    path: '/index.php/research',
    name: 'research',
    component: Research,
    children: [
        { path: 'publications/', name: 'publicationsAll', component: Publications },
        { path: 'publications/:idDepartment', name: 'publications', component: Publications },
        { path: 'publications/grants/:idDepartmentGrants', name: 'grantsPublications', component: Publications},
        { path: 'grants/', name: 'grantsAllPublications', component: Grants },
        { path: 'lectures/', name: 'lecturesAll', component: Lectures },
        { path: 'lectures/:idDepartment', name: 'lectures', component: Lectures },
        { path: 'collaboration/', name: 'collaboration', component: Collaboration },
    ]
}];
