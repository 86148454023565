const state={
    news: [],
    year:0,
    more:1,
    nbNews:0,
    showMore:true,
    more:0,
};
const getters = {
    year: state => {
        return state.year;
    },
    news: state => {
        return state.news;
    },
    more: state => {
        return state.more;
    },
    nbNews: state => {
        return state.nbNews;
    },
    showMore:state => {
        return state.showMore;
    }
};
const mutations = {
    'SET_NEWS_YEAR' (state, payload) {
        state.year =  payload.year;
        state.news = payload.news;
    },
    'SET_MORE'(state, payload) {
        state.more = payload;
        // get nb news in next page
        var newsToShow = eval(state.nbNews - ((payload+1) * 8));
        if(newsToShow > 0){
            state.showMore=true;
        }
        else{
            state.showMore=false;
            state.more=0;
        }
    },
    'SET_NB_NEWS'(state, payload) {
        state.nbNews = payload;
    },
    'SET_SHOW_MORE'(state,payload){
        state.showMore = payload;
    }
};
const actions = {
    async setNewsYear ({ commit }, year) {
        try{
            const result = await axios.get('/index.php/api/news/archive/'+year);
            if(result){
                const payload ={news:result.data, year:year};
                commit('SET_NEWS_YEAR', payload);
            }
        }
        catch (error) {
            throw error;
        }
    },
    async countNews ({ commit }) {
        try{
            const result = await axios.get('/index.php/api/news/countnews');
            if(result){
                const nbNews = result.data;
                commit('SET_NB_NEWS', nbNews);
            }
        }
        catch (error) {
            throw error;
        }
        if(state.nbNews < 10){
            commit('SET_MORE',0);
            commit('SET_SHOW_MORE',false);

        }
        else{
            commit('SET_MORE',1);
            commit('SET_SHOW_MORE',true);
        }
    },
    setMore({ commit },more){
        commit('SET_MORE',more);
    },
    incMore({commit},payload){
        var more = payload;
        commit('SET_SHOW_MORE',false);
        commit('SET_MORE',more);
        
    },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
