/**
 *
 * BO dashboard
 */
const store = require('../../store/store');

const Dashboard = resolve => {
    require.ensure(['../../components/bo/Dashboard.vue'], () => {
        resolve(require('../../components/bo/Dashboard.vue'));
    });
};
const DashboardList = resolve => {
    require.ensure(['../../components/bo/dashboard/DashboardList.vue'], () => {
        resolve(require('../../components/bo/dashboard/DashboardList.vue'));
    });
};
//Users
const UsersList = resolve => {
    require.ensure(['../../components/bo/users/UsersList.vue'], () => {
        resolve(require('../../components/bo/users/UsersList.vue'));
    });
};
const UserEditPermissions = resolve => {
    require.ensure(['../../components/bo/users/UserEditPermissions.vue'], () => {
        resolve(require('../../components/bo/users/UserEditPermissions.vue'));
    });
};
const UsersExternalList = resolve => {
    require.ensure(['../../components/bo/users/UsersExternalList.vue'], () => {
        resolve(require('../../components/bo/users/UsersExternalList.vue'));
    });
};
const UserExternalDelete = resolve => {
    require.ensure(['../../components/bo/users/UserExternalDelete.vue'], () => {
        resolve(require('../../components/bo/users/UserExternalDelete.vue'));
    });
};
const UserExternalAdd = resolve => {
    require.ensure(['../../components/bo/users/UserExternalAdd.vue'], () => {
        resolve(require('../../components/bo/users/UserExternalAdd.vue'));
    });
};
const UserEditRoles = resolve => {
    require.ensure(['../../components/bo/users/UserEditRoles.vue'], () => {
        resolve(require('../../components/bo/users/UserEditRoles.vue'));
    });
};
const UsersCleaning = resolve => {
    require.ensure(['../../components/bo/users/UsersCleaning.vue'], () => {
        resolve(require('../../components/bo/users/UsersCleaning.vue'));
    });
};
//People
const ECvisitors = resolve => {
    require.ensure(['../../components/bo/people/ECvisitors.vue'], () => {
        resolve(require('../../components/bo/people/ECvisitors'));
    });
};
const ECvisitorAdd = resolve => {
    require.ensure(['../../components/bo/people/visitors/ECvisitorAdd.vue'], () => {
        resolve(require('../../components/bo/people/visitors/ECvisitorAdd'));
    });
};
const ECvisitorEdit = resolve => {
    require.ensure(['../../components/bo/people/visitors/ECvisitorEdit.vue'], () => {
        resolve(require('../../components/bo/people/visitors/ECvisitorEdit'));
    });
};
const ECvisitorDelete = resolve => {
    require.ensure(['../../components/bo/people/visitors/ECvisitorDelete.vue'], () => {
        resolve(require('../../components/bo/people/visitors/ECvisitorDelete'));
    });
};
const Researchers = resolve => {
    require.ensure(['../../components/bo/people/Researchers.vue'], () => {
        resolve(require('../../components/bo/people/Researchers.vue'));
    });
};
const FormerResearchers = resolve => {
    require.ensure(['../../components/bo/people/former/FormerResearchers.vue'], () => {
        resolve(require('../../components/bo/people/former/FormerResearchers.vue'));
    });
};
const Postdocs = resolve => {
    require.ensure(['../../components/bo/people/Postdocs.vue'], () => {
        resolve(require('../../components/bo/people/Postdocs.vue'));
    });
};
const PersonEdit = resolve => {
    require.ensure(['../../components/bo/people/persons/PersonEdit.vue'], () => {
        resolve(require('../../components/bo/people/persons/PersonEdit.vue'));
    });
};

const PersonForm = resolve => {
    require.ensure(['../../components/bo/people/persons/PersonEdit.vue'], () => {
        resolve(require('../../components/bo/people/persons/PersonEdit.vue'));
    });
};
const PersonArchive = resolve => {
    require.ensure(['../../components/bo/people/PersonArchive.vue'], () => {
        resolve(require('../../components/bo/people/PersonArchive.vue'));
    });
};
const ResearcherEdit = resolve => {
    require.ensure(['../../components/bo/people/persons/ResearcherEdit.vue'], () => {
        resolve(require('../../components/bo/people/persons/ResearcherEdit.vue'));
    });
};
const ResearcherArchive = resolve => {
    require.ensure(['../../components/bo/people/ResearcherArchive.vue'], () => {
        resolve(require('../../components/bo/people/ResearcherArchive.vue'));
    });
};
const ResearcherChangeCategorie = resolve => {
    require.ensure(['../../components/bo/people/persons/ResearcherChangeCategorie.vue'], () => {
        resolve(require('../../components/bo/people/persons/ResearcherChangeCategorie.vue'));
    });
};
const StaffBo = resolve => {
    require.ensure(['../../components/bo/people/StaffBo.vue'], () => {
        resolve(require('../../components/bo/people/StaffBo.vue'));
    });
};
const Visitors = resolve => {
    require.ensure(['../../components/bo/people/Visitors.vue'], () => {
        resolve(require('../../components/bo/people/Visitors.vue'));
    });
};
const VisitorAdd = resolve => {
    require.ensure(['../../components/bo/people/visitors/VisitorAdd.vue'], () => {
        resolve(require('../../components/bo/people/visitors/VisitorAdd'));
    });
};
const VisitorEdit = resolve => {
    require.ensure(['../../components/bo/people/visitors/VisitorEdit.vue'], () => {
        resolve(require('../../components/bo/people/visitors/VisitorEdit'));
    });
};
const VisitorDelete = resolve => {
    require.ensure(['../../components/bo/people/visitors/VisitorDelete.vue'], () => {
        resolve(require('../../components/bo/people/visitors/VisitorDelete'));
    });
};
const VisitorStayAdd = resolve => {
    require.ensure(['../../components/bo/people/visitors/VisitorStayAdd.vue'], () => {
        resolve(require('../../components/bo/people/visitors/VisitorStayAdd'));
    });
};
//News
const News = resolve => {
    require.ensure(['../../components/bo/news/News.vue'], () => {
        resolve(require('../../components/bo/news/News.vue'));
    });
};

const NewsArchive = resolve => {
    require.ensure(['../../components/bo/news/NewsArchive.vue'], () => {
        resolve(require('../../components/bo/news/NewsArchive.vue'));
    });
};
const NewsEdit = resolve => {
    require.ensure(['../../components/bo/news/NewsEdit.vue'], () => {
        resolve(require('../../components/bo/news/NewsEdit.vue'));
    });
};

const NewsAdd = resolve => {
    require.ensure(['../../components/bo/news/NewsAdd.vue'], () => {
        resolve(require('../../components/bo/news/NewsAdd.vue'));
    });
};

const NewsReorder = resolve => {
    require.ensure(['../../components/bo/news/NewsReorder.vue'], () => {
        resolve(require('../../components/bo/news/NewsReorder.vue'));
    });
};

// Publications
const Preprints = resolve => {
    require.ensure(['../../components/bo/research/publications/Preprints.vue'], () => {
        resolve(require('../../components/bo/research/publications/Preprints.vue'));
    });
};
const Collaboration = resolve => {
    require.ensure(['../../components/bo/research/Collaboration.vue'], () => {
        resolve(require('../../components/bo/research/Collaboration.vue'));
    });
};
const CollaborationEdit = resolve => {
    require.ensure(['../../components/bo/research/collaboration/CollaborationEdit.vue'], () => {
        resolve(require('../../components/bo/research/collaboration/CollaborationEdit.vue'));
    });
};
const Lecture = resolve => {
    require.ensure(['../../components/bo/research/publications/Lecture.vue'], () => {
        resolve(require('../../components/bo/research/publications/Lecture.vue'));
    });
};
const PreprintRetract = resolve => {
    require.ensure(['../../components/bo/research/publications/PreprintRetract.vue'], () => {
        resolve(require('../../components/bo/research/publications/PreprintRetract.vue'));
    });
};
const PreprintModify = resolve => {
    require.ensure(['../../components/bo/research/publications/PreprintModify.vue'], () => {
        resolve(require('../../components/bo/research/publications/PreprintModify.vue'));
    });
};
const PreprintTransfer = resolve => {
    require.ensure(['../../components/bo/research/publications/PreprintTransfer.vue'], () => {
        resolve(require('../../components/bo/research/publications/PreprintTransfer.vue'));
    });
};
const PublicationsAuthor = resolve => {
    require.ensure(['../../components/bo/research/publications/PublicationsAuthor'], () => {
        resolve(require('../../components/bo/research/publications/PublicationsAuthor.vue'));
    });
};
const Publications = resolve => {
    require.ensure(['../../components/bo/research/publications/Publications.vue'], () => {
        resolve(require('../../components/bo/research/publications/Publications.vue'));
    });
};
const PublicationAdd = resolve => {
    require.ensure(['../../components/bo/research/publications/PublicationAdd.vue'], () => {
        resolve(require('../../components/bo/research/publications/PublicationAdd.vue'));
    });
};
const PublicationEdit = resolve => {
    require.ensure(['../../components/bo/research/publications/PublicationEdit.vue'], () => {
        resolve(require('../../components/bo/research/publications/PublicationEdit.vue'));
    });
};
const PublicationDelete = resolve => {
    require.ensure(['../../components/bo/research/publications/PublicationDelete.vue'], () => {
        resolve(require('../../components/bo/research/publications/PublicationDelete.vue'));
    });
};
const Thesis = resolve => {
    require.ensure(['../../components/bo/research/publications/Thesis.vue'], () => {
        resolve(require('../../components/bo/research/publications/Thesis.vue'));
    });
};
const FormAuthorsOrder = resolve => {
    require.ensure(['../../components/bo/research/publications/FormAuthorsOrder.vue'], () => {
        resolve(require('../../components/bo/research/publications/FormAuthorsOrder.vue'));
    });
}
//ASEP
const FormAsep = resolve => {
require.ensure(['../../components/bo/research/publications/ASEP/FormAsep.vue'], () => {
        resolve(require('../../components/bo/research/publications/ASEP/FormAsep.vue'));
    });
};
const FormUpdateStatusAsep = resolve => {
require.ensure(['../../components/bo/research/publications/ASEP/FormUpdateStatusAsep.vue'], () => {
        resolve(require('../../components/bo/research/publications/ASEP/FormUpdateStatusAsep.vue'));
    });
};
const ManageAsepStatus = resolve => {
require.ensure(['../../components/bo/research/publications/ASEP/ManageAsepStatus.vue'], () => {
        resolve(require('../../components/bo/research/publications/ASEP/ManageAsepStatus.vue'));
    });
};
const FormEmailAsep = resolve => {
require.ensure(['../../components/bo/research/publications/ASEP/FormEmailAsep.vue'], () => {
        resolve(require('../../components/bo/research/publications/ASEP/FormEmailAsep.vue'));
    });
};
const FormUpdateUrlRepository = resolve => {
require.ensure(['../../components/bo/research/publications/ASEP/FormUpdateUrlRepository.vue'], () => {
        resolve(require('../../components/bo/research/publications/ASEP/FormUpdateUrlRepository.vue'));
    });
};
//Events
const Conferences = resolve => {
    require.ensure(['../../components/bo/events/Conferences.vue'], () => {
        resolve(require('../../components/bo/events/Conferences.vue'));
    });
};
const ConferenceProgramEdit = resolve => {
    require.ensure(['../../components/bo/events/conferences/ProgramEdit.vue'], () => {
        resolve(require('../../components/bo/events/conferences/ProgramEdit.vue'));
    });
};
const ConferenceProgramAdd = resolve => {
    require.ensure(['../../components/bo/events/conferences/ProgramAdd.vue'], () => {
        resolve(require('../../components/bo/events/conferences/ProgramAdd.vue'));
    });
};
const Seminar = resolve => {
    require.ensure(['../../components/bo/events/seminars/Seminar.vue'], () => {
        resolve(require('../../components/bo/events/seminars/Seminar.vue'));
    });
};
const SeminarEdit = resolve => {
    require.ensure(['../../components/bo/events/seminars/SeminarEdit.vue'], () => {
        resolve(require('../../components/bo/events/seminars/SeminarEdit.vue'));
    });
};
const SeminarAdd = resolve => {
    require.ensure(['../../components/bo/events/seminars/SeminarAdd.vue'], () => {
        resolve(require('../../components/bo/events/seminars/SeminarAdd.vue'));
    });
};

const Seminars = resolve => {
    require.ensure(['../../components/bo/events/Seminars.vue'], () => {
        resolve(require('../../components/bo/events/Seminars.vue'));
    });
};
const ProgramEdit = resolve => {
    require.ensure(['../../components/bo/events/seminars/ProgramEdit.vue'], () => {
        resolve(require('../../components/bo/events/seminars/ProgramEdit.vue'));
    });
};
const ProgramAdd = resolve => {
    require.ensure(['../../components/bo/events/seminars/ProgramAdd.vue'], () => {
        resolve(require('../../components/bo/events/seminars/ProgramAdd.vue'));
    });
};
const Awards = resolve => {
    require.ensure(['../../components/bo/events/Awards.vue'], () => {
        resolve(require('../../components/bo/events/Awards.vue'));
    });
};
const Showcron = resolve => {
    require.ensure(['../../components/bo/cron/Showcron'], () => {
        resolve(require('../../components/bo/cron/Showcron'));
    });
};
const AddAward = resolve => {
    require.ensure(['../../components/bo/events/awards/AddAward.vue'], () => {
        resolve(require('../../components/bo/events/awards/AddAward.vue'));
    });
};
const EditAward = resolve => {
    require.ensure(['../../components/bo/events/awards/EditAward.vue'], () => {
        resolve(require('../../components/bo/events/awards/EditAward.vue'));
    });
};
const DeleteAward = resolve => {
    require.ensure(['../../components/bo/events/awards/DeleteAward.vue'], () => {
        resolve(require('../../components/bo/events/awards/DeleteAward.vue'));
    });
};
const Edlectures = resolve => {
    require.ensure(['../../components/bo/events/Edlectures.vue'], () => {
        resolve(require('../../components/bo/events/Edlectures.vue'));
    });
};
const EdlecturesProgramAdd = resolve => {
    require.ensure(['../../components/bo/events/eclectures/ProgramAdd.vue'], () => {
        resolve(require('../../components/bo/events/eclectures/ProgramAdd.vue'));
    });
};
const EdlecturesProgramEdit = resolve => {
    require.ensure(['../../components/bo/events/eclectures/ProgramEdit.vue'], () => {
        resolve(require('../../components/bo/events/eclectures/ProgramEdit.vue'));
    });
};
const EventsList = resolve => {
    require.ensure(['../../components/bo/events/EventsList.vue'], () => {
        resolve(require('../../components/bo/events/EventsList.vue'));
    });
};
// About
const About = resolve => {
    require.ensure(['../../components/bo/about/About.vue'], () => {
        resolve(require('../../components/bo/about/About.vue'));
    });
};
const Contact = resolve => {
    require.ensure(['../../components/bo/about/contact/Contact.vue'], () => {
        resolve(require('../../components/bo/about/contact/Contact.vue'));
    });
};
const Mission = resolve => {
    require.ensure(['../../components/bo/about/Mission.vue'], () => {
        resolve(require('../../components/bo/about/Mission.vue'));
    });
};
const MissionEdit = resolve => {
    require.ensure(['../../components/bo/about/mission/MissionEdit.vue'], () => {
        resolve(require('../../components/bo/about/mission/MissionEdit.vue'));
    });
};
const HRExcellence = resolve => {
    require.ensure(['../../components/bo/about/HRExcellence.vue'], () => {
        resolve(require('../../components/bo/about/HRExcellence.vue'));
    });
};
const HrEdit = resolve => {
    require.ensure(['../../components/bo/about/hrexcellence/HrEdit.vue'], () => {
        resolve(require('../../components/bo/about/hrexcellence/HrEdit.vue'));
    });
};
const Documents = resolve => {
    require.ensure(['../../components/bo/about/Documents.vue'], () => {
        resolve(require('../../components/bo/about/Documents.vue'));
    });
};
const DocumentAdd = resolve => {
    require.ensure(['../../components/bo/about/documents/DocumentAdd.vue'], () => {
        resolve(require('../../components/bo/about/documents/DocumentAdd.vue'));
    });
};
const DocumentDelete = resolve => {
    require.ensure(['../../components/bo/about/documents/DocumentDelete.vue'], () => {
        resolve(require('../../components/bo/about/documents/DocumentDelete.vue'));
    });
};
const DocumentsOrder = resolve => {
    require.ensure(['../../components/bo/about/documents/DocumentsOrder.vue'], () => {
        resolve(require('../../components/bo/about/documents/DocumentsOrder.vue'));
    });
};
const Reports = resolve => {
    require.ensure(['../../components/bo/about/Reports.vue'], () => {
        resolve(require('../../components/bo/about/Reports.vue'));
    });
};
const ReportAdd = resolve => {
    require.ensure(['../../components/bo/about/reports/ReportAdd.vue'], () => {
        resolve(require('../../components/bo/about/reports/ReportAdd.vue'));
    });
};
const ReportDelete = resolve => {
    require.ensure(['../../components/bo/about/reports/ReportDelete.vue'], () => {
        resolve(require('../../components/bo/about/reports/ReportDelete.vue'));
    });
};
const ReportsOrder = resolve => {
    require.ensure(['../../components/bo/about/reports/ReportsOrder.vue'], () => {
        resolve(require('../../components/bo/about/reports/ReportsOrder.vue'));
    });
};
//
// Structure
//
const Structure = resolve => {
    require.ensure(['../../components/bo/about/Structure.vue'], () => {
        resolve(require('../../components/bo/about/Structure.vue'));
    });
};
const StructureEditPerson = resolve => {
    require.ensure(['../../components/bo/about/structure/StructureEditPerson.vue'], () => {
        resolve(require('../../components/bo/about/structure/StructureEditPerson.vue'));
    });
};
const StructureEditSupervisoryPerson = resolve => {
    require.ensure(['../../components/bo/about/structure/StructureEditSupervisoryPerson.vue'], () => {
        resolve(require('../../components/bo/about/structure/StructureEditSupervisoryPerson.vue'));
    });
};
const StructureBoardDeletePerson = resolve => {
    require.ensure(['../../components/bo/about/structure/StructureBoardDeletePerson.vue'], () => {
        resolve(require('../../components/bo/about/structure/StructureBoardDeletePerson.vue'));
    });
};
const StructureBoardAddPerson = resolve => {
    require.ensure(['../../components/bo/about/structure/StructureBoardAddPerson.vue'], () => {
        resolve(require('../../components/bo/about/structure/StructureBoardAddPerson.vue'));
    });
};
const StructureBoardOrderPerson = resolve => {
    require.ensure(['../../components/bo/about/structure/StructureBoardOrderPerson.vue'], () => {
        resolve(require('../../components/bo/about/structure/StructureBoardOrderPerson.vue'));
    });
};
const StructureBoardOrderInternalPerson = resolve => {
    require.ensure(['../../components/bo/about/structure/StructureBoardOrderInternalPerson.vue'], () => {
        resolve(require('../../components/bo/about/structure/StructureBoardOrderInternalPerson.vue'));
    });
};
const StructureBoardLink = resolve => {
    require.ensure(['../../components/bo/about/structure/StructureBoardLink.vue'], () => {
        resolve(require('../../components/bo/about/structure/StructureBoardLink.vue'));
    });
};
const StructureBoardDescription = resolve => {
    require.ensure(['../../components/bo/about/structure/StructureBoardDescription.vue'], () => {
        resolve(require('../../components/bo/about/structure/StructureBoardDescription.vue'));
    });
};
// Departments
const Departments = resolve => {
    require.ensure(['../../components/bo/departments/Departments.vue'], () => {
        resolve(require('../../components/bo/departments/Departments.vue'));
    });
};
const  Department = resolve => {
    require.ensure(['../../components/bo/departments/Department.vue'], () => {
        resolve(require('../../components/bo/departments/Department.vue'));
    });
};
const  DescriptionAdd = resolve => {
    require.ensure(['../../components/bo/departments/DescriptionAdd.vue'], () => {
        resolve(require('../../components/bo/departments/DescriptionAdd.vue'));
    });
};
const  DescriptionEdit = resolve => {
    require.ensure(['../../components/bo/departments/DescriptionEdit.vue'], () => {
        resolve(require('../../components/bo/departments/DescriptionEdit.vue'));
    });
};
const  ThemeAdd = resolve => {
    require.ensure(['../../components/bo/departments/ThemeAdd.vue'], () => {
        resolve(require('../../components/bo/departments/ThemeAdd.vue'));
    });
};
const  ThemeEdit = resolve => {
    require.ensure(['../../components/bo/departments/ThemeEdit.vue'], () => {
        resolve(require('../../components/bo/departments/ThemeEdit.vue'));
    });
};
const  ThemeDelete = resolve => {
    require.ensure(['../../components/bo/departments/ThemeDelete.vue'], () => {
        resolve(require('../../components/bo/departments/ThemeDelete.vue'));
    });
};
const  DepartmentArchive = resolve => {
    require.ensure(['../../components/bo/departments/DepartmentArchive.vue'], () => {
        resolve(require('../../components/bo/departments/DepartmentArchive.vue'));
    });
};
const  DepartmentAdd = resolve => {
    require.ensure(['../../components/bo/departments/DepartmentAdd.vue'], () => {
        resolve(require('../../components/bo/departments/DepartmentAdd.vue'));
    });
};
const  DepartmentEdit = resolve => {
    require.ensure(['../../components/bo/departments/DepartmentEdit.vue'], () => {
        resolve(require('../../components/bo/departments/DepartmentEdit.vue'));
    });
};
//Library
const LibraryHome = resolve => {
    require.ensure(['../../components/bo/library/LibraryHome.vue'], () => {
        resolve(require('../../components/bo/library/LibraryHome.vue'));
    });
};
const HoursEdit = resolve => {
    require.ensure(['../../components/bo/library/home/HoursEdit.vue'], () => {
        resolve(require('../../components/bo/library/home/HoursEdit.vue'));
    });
};
const MessageEdit = resolve => {
    require.ensure(['../../components/bo/library/home/MessageEdit.vue'], () => {
        resolve(require('../../components/bo/library/home/MessageEdit.vue'));
    });
};
const AddressEdit = resolve => {
    require.ensure(['../../components/bo/library/home/AddressEdit.vue'], () => {
        resolve(require('../../components/bo/library/home/AddressEdit.vue'));
    });
};
const ElectronicSources = resolve => {
    require.ensure(['../../components/bo/library/ElectronicSources.vue'], () => {
        resolve(require('../../components/bo/library/ElectronicSources.vue'));
    });
};
const EResourceEdit = resolve => {
    require.ensure(['../../components/bo/library/eresource/EResourceEdit.vue'], () => {
        resolve(require('../../components/bo/library/eresource/EResourceEdit.vue'));
    });
};
const LibraryAsepRiv = resolve => {
    require.ensure(['../../components/bo/library/LibraryAsepRiv.vue'], () => {
        resolve(require('../../components/bo/library/LibraryAsepRiv.vue'));
    });
};
const AsepEdit = resolve => {
    require.ensure(['../../components/bo/library/home/AsepEdit.vue'], () => {
        resolve(require('../../components/bo/library/home/AsepEdit.vue'));
    });
};
const RivEdit = resolve => {
    require.ensure(['../../components/bo/library/home/RivEdit.vue'], () => {
        resolve(require('../../components/bo/library/home/RivEdit.vue'));
    });
};
const LibraryRules = resolve => {
    require.ensure(['../../components/bo/library/LibraryRules.vue'], () => {
        resolve(require('../../components/bo/library/LibraryRules.vue'));
    });
};
const RulesEdit = resolve => {
    require.ensure(['../../components/bo/library/rules/RulesEdit.vue'], () => {
        resolve(require('../../components/bo/library/rules/RulesEdit.vue'));
    });
};
const NewBooks = resolve => {
    require.ensure(['../../components/bo/library/NewBooks.vue'], () => {
        resolve(require('../../components/bo/library/NewBooks.vue'));
    });
};
const NewBooksEdit = resolve => {
    require.ensure(['../../components/bo/library/newBooks/NewBooksEdit.vue'], () => {
        resolve(require('../../components/bo/library/newBooks/NewBooksEdit.vue'));
    });
};
//Journals
const Journal = resolve => {
    require.ensure(['../../components/bo/journals/Journal.vue'], () => {
        resolve(require('../../components/bo/journals/Journal.vue'));
    });
};
const ZbMath = resolve => {
    require.ensure(['../../components/bo/journals/ZbMath.vue'], () => {
        resolve(require('../../components/bo/journals/ZbMath.vue'));
    });
};
const ZbMathEdit = resolve => {
    require.ensure(['../../components/bo/journals/zbmath/ZbmathEdit.vue'], () => {
        resolve(require('../../components/bo/journals/zbmath/ZbmathEdit.vue'));
    });
};
const JournalEdit = resolve => {
    require.ensure(['../../components/bo/journals/journal/JournalEdit.vue'], () => {
        resolve(require('../../components/bo/journals/journal/JournalEdit.vue'));
    });
};
const DataJournalEdit = resolve => {
    require.ensure(['../../components/bo/journals/journal/DataJournalEdit.vue'], () => {
        resolve(require('../../components/bo/journals/journal/DataJournalEdit.vue'));
    });
};
const JournalDescriptionEdit = resolve => {
    require.ensure(['../../components/bo/journals/journal/JournalDescriptionEdit.vue'], () => {
        resolve(require('../../components/bo/journals/journal/JournalDescriptionEdit.vue'));
    });
};

//Grants
const Grants = resolve => {
    require.ensure(['../../components/bo/grants/Grants.vue'], () => {
        resolve(require('../../components/bo/grants/Grants.vue'));
    });
};
const GrantsPast = resolve => {
    require.ensure(['../../components/bo/grants/GrantsPast.vue'], () => {
        resolve(require('../../components/bo/grants/GrantsPast.vue'));
    });
};
const GrantAdd = resolve => {
    require.ensure(['../../components/bo/grants/GrantAdd.vue'], () => {
        resolve(require('../../components/bo/grants/GrantAdd.vue'));
    });
};
const GrantEdit = resolve => {
    require.ensure(['../../components/bo/grants/GrantEdit.vue'], () => {
        resolve(require('../../components/bo/grants/GrantEdit.vue'));
    });
};
const GrantDelete = resolve => {
    require.ensure(['../../components/bo/grants/GrantDelete.vue'], () => {
        resolve(require('../../components/bo/grants/GrantDelete.vue'));
    });
};
const GrantInvestigators = resolve => {
    require.ensure(['../../components/bo/grants/GrantInvestigators.vue'], () => {
        resolve(require('../../components/bo/grants/GrantInvestigators.vue'));
    });
};
const GrantDetailView = resolve => {
    require.ensure(['../../components/bo/grants/GrantDetailView.vue'], () => {
        resolve(require('../../components/bo/grants/GrantDetailView.vue'));
    });
};

// VPNError
const VPNError = resolve => {
    require.ensure(['../../components/bo/VPNError.vue'], () => {
        resolve(require('../../components/bo/VPNError.vue'));
    });
};

function pathGuard(to, from, next) {
    if (to.name == 'userlogin' || to.name == 'vpnerror.bo') {
        next();
        return;
    }

    let vpnPromise = store.default.dispatch("header/checkVPN");
    let loginPromise = store.default.dispatch("header/set_userActive", 1);
    Promise.all([vpnPromise, loginPromise]).then(() => {
        if (store.default.getters["header/authenticated"]) {
            next();
        } else {
            next({ name: 'userlogin' });
        }
    }).catch( () => {
        if (!store.default.getters["header/authenticated"]) {
            next({ name: 'userlogin' });
        }
    });
}

export const DashboardAddresses = [{
    path: '/index.php/user/dashboard',
    name: 'userdashboard',
    component: Dashboard,
    beforeEnter: pathGuard,
    children: [
        { path: 'dashboardlist', name: 'dashboardlist', component: DashboardList, beforeEnter: pathGuard },
        //Users
        { path: 'users', name: "users.bo", component: UsersList, beforeEnter: pathGuard },
        { path: 'externals', name: "externals.bo", component: UsersExternalList, beforeEnter: pathGuard },
        { path: 'cleaning', name: "cleaning.users.bo", component: UsersCleaning, beforeEnter: pathGuard },
        { path: 'user/:id/external/delete', name: "user.external.delete", component: UserExternalDelete, beforeEnter: pathGuard },
        { path: 'user/:id/role/edit/', name: "role.bo", component: UserEditRoles, beforeEnter: pathGuard },
        { path: 'user/add', name: "user.add.bo", component: UserExternalAdd, beforeEnter: pathGuard },
        { path: 'user/:id/permissions/edit/', name: "user.permissions.bo", component: UserEditPermissions, beforeEnter: pathGuard },

        //People
        // [People] E Cech visitors
        { path: 'ecvisitors', name: "ecvisitors.bo", component: ECvisitors, beforeEnter: pathGuard },
        { path: 'ecvisitors/add', name: "ecvisitors.bo.add", component: ECvisitorAdd, beforeEnter: pathGuard },
        { path: 'ecvisitors/edit/:id', name: "ecvisitors.bo.edit", component: ECvisitorEdit, beforeEnter: pathGuard },
        { path: 'ecvisitors/delete/:id', name: "ecvisitors.bo.delete", component: ECvisitorDelete, beforeEnter: pathGuard },
        // [People] Visitors
        { path: 'visitors', name: "visitors.bo", component: Visitors, beforeEnter: pathGuard },
        { path: 'visitors/add', name: "visitors.bo.add", component: VisitorAdd, beforeEnter: pathGuard },
        { path: 'visitors/edit/:id', name: "visitors.bo.edit", component: VisitorEdit, beforeEnter: pathGuard },
        { path: 'visitors/delete/:id', name: "visitors.bo.delete", component: VisitorDelete, beforeEnter: pathGuard },
        { path: 'visitors/stay/add/:id', name: "visitors.bo.stay", component: VisitorStayAdd, beforeEnter: pathGuard },

        //  [People] Researchers
        { path: 'researchers', name: "researchers.bo", component: Researchers, beforeEnter: pathGuard },
        { path: 'former', name: "former.bo", component: FormerResearchers, beforeEnter: pathGuard },
        { path: 'postdocs', name: "postdocs.bo", component: Postdocs, beforeEnter: pathGuard },
        { path: 'researcher/:person_id/edit/:category_id', name: "bo.researcher.edit", component: ResearcherEdit, beforeEnter: pathGuard },
        { path: 'researcher/:person_id/change/:category_id', name: "bo.researcher.change", component: ResearcherChangeCategorie, beforeEnter: pathGuard },
        { path: 'person/:person_id/edit/:category_id', name: "bo.person.edit", component: PersonEdit, beforeEnter: pathGuard },
        { path: 'researcher/:person_id/archive/:category_id', name: "bo.researcher.archive", component: ResearcherArchive, beforeEnter: pathGuard },
        { path: 'person/:person_id/edit/:category_id', name: "bo.person.archive", component: PersonArchive, beforeEnter: pathGuard },
        { path: 'staff', name: "staff.bo", component: StaffBo, beforeEnter: pathGuard },

        //News
        { path: 'news', name: "news.bo", component: News, beforeEnter: pathGuard },
        { path: 'news/archive/:id', name: "news.archive.bo", component: NewsArchive, beforeEnter: pathGuard },
        { path: 'news/edit/:id', name: "news.edit.bo", component: NewsEdit, beforeEnter: pathGuard },
        { path: 'news/add', name: "news.add.bo", component: NewsAdd, beforeEnter: pathGuard },
        { path: 'news/reorder', name: "news.reorder.bo", component: NewsReorder, beforeEnter: pathGuard },
        //Research
        { path: 'preprints', name: "preprints.all.bo", component: Preprints, beforeEnter: pathGuard },
        { path: 'publications', name: "publications.all.bo", component: Publications, beforeEnter: pathGuard },
        { path: 'publication/repository/:id/:type', name: "publication.repository", component:FormUpdateUrlRepository, beforeEnter: pathGuard },

        { path: 'collaboration', name: "collaboration.bo", component: Collaboration, beforeEnter: pathGuard },
        { path: 'collaboration/edit/:language/:id/:type', name: "collaboration.edit", component: CollaborationEdit, beforeEnter: pathGuard },
        { path: 'lectures', name: "lectures.bo", component: Lecture, beforeEnter: pathGuard },
        { path: 'publication.bo', name: "publication.bo", component: PublicationsAuthor, beforeEnter: pathGuard },
        { path: 'publication.bo/add/:type/', name: "publication.bo.add", component: PublicationAdd, beforeEnter: pathGuard },
        { path: 'publication.bo/edit/:type/:id', name: "publication.bo.edit", component: PublicationEdit, beforeEnter: pathGuard },
        { path: 'publication.bo/delete/:type/:id', name: "publication.bo.delete", component: PublicationDelete, beforeEnter: pathGuard },
        { path: 'publication.bo.tranfer/:id', name: "publication.bo.tranfer", component: PreprintTransfer, beforeEnter: pathGuard },
        { path: 'publication.bo.retract/:type/:id/:mode', name: "publication.bo.retract", component: PreprintRetract, beforeEnter: pathGuard },
        { path: 'publication.bo.modify/:type/:id', name: "publication.bo.modify", component: PreprintModify, beforeEnter: pathGuard },
        { path: 'thesis', name: "thesis.bo", component: Thesis, beforeEnter: pathGuard },
        { path: 'authors.bo.modify/:id', name: "authors.bo.modify", component:FormAuthorsOrder, beforeEnter: pathGuard },

        // ASEP publication
        { path: 'asep/form/:asep/:type/:id', name: "asep.form", component: FormAsep, beforeEnter: pathGuard },
        { path: 'asep/edit/:asep/:type/:id', name: "asep.edit", component: FormUpdateStatusAsep, beforeEnter: pathGuard },
        { path: 'asep/manage/status', name: "asep.status.bo", component: ManageAsepStatus, beforeEnter: pathGuard },
        { path: 'asep/email/data/:id/:type', name: "asep.email.data", component: FormEmailAsep, beforeEnter: pathGuard },

        // Events
        { path: 'awards', name: "awards.bo", component: Awards, beforeEnter: pathGuard },
        { path: 'showcron', name: "showcron.bo", component: Showcron, beforeEnter: pathGuard },
        { path: 'award.bo/:id/edit', name: "award.edit", component: EditAward, beforeEnter: pathGuard },
        { path: 'award.bo/add', name: "award.add", component: AddAward, beforeEnter: pathGuard },
        { path: 'award.bo/:id/delete', name: "award.delete", component: DeleteAward, beforeEnter: pathGuard },
        { path: 'conference', name: "conferences.bo", component: Conferences, beforeEnter: pathGuard },
        { path: 'conference/edit/:id', name: "conferences.edit.bo", component: ConferenceProgramEdit, beforeEnter: pathGuard },
        { path: 'conference/add/', name: "conferences.add.bo", component: ConferenceProgramAdd, beforeEnter: pathGuard },
        { path: 'edlectures', name: "edlectures.bo", component: Edlectures, beforeEnter: pathGuard },
        { path: 'edlectures/edit/:id', name: "edlectures.edit.bo", component: EdlecturesProgramEdit, beforeEnter: pathGuard },
        { path: 'edlectures/add/', name: "edlectures.add.bo", component: EdlecturesProgramAdd, beforeEnter: pathGuard },
        { path: 'events', name: "events.bo", component: EventsList, beforeEnter: pathGuard },
        { path: 'seminars', name: 'seminars.bo', component: Seminars, beforeEnter: pathGuard },
        { path: 'seminars/add', name: "seminars.add.bo", component: SeminarAdd, beforeEnter: pathGuard },
        { path: 'seminar/:id', name: "seminar.bo", component: Seminar, beforeEnter: pathGuard },
        { path: 'seminar/edit/:id', name: "seminar.edit.bo", component: SeminarEdit, beforeEnter: pathGuard },
        { path: 'seminar/program/edit/:id', name: "seminar.program.edit.bo", component: ProgramEdit, beforeEnter: pathGuard },
        { path: 'seminar/program/add/:id', name: "seminar.program.addto.bo", component: ProgramAdd, beforeEnter: pathGuard },
        { path: 'seminar/program/add/', name: "seminar.program.add.bo", component: ProgramAdd, beforeEnter: pathGuard },
        /**
         * ABOUT
         */
        /**
         * reports + documents
         */

        { path: 'documents', name: "documents.bo", component: Documents, beforeEnter: pathGuard },
        { path: 'documents/order', name: "documents.order", component: DocumentsOrder, beforeEnter: pathGuard },
        { path: 'documents/add', name: "documents.add", component: DocumentAdd, beforeEnter: pathGuard },
        { path: 'documents/delete/:id', name: "documents.delete", component: DocumentDelete, beforeEnter: pathGuard },
        { path: 'reports', name: "reports.bo", component: Reports, beforeEnter: pathGuard },
        { path: 'reports/order', name: "reports.order", component: ReportsOrder, beforeEnter: pathGuard },
        { path: 'reports/add', name: "reports.add", component: ReportAdd, beforeEnter: pathGuard },
        { path: 'reports/delete/:id', name: "reports.delete", component: ReportDelete, beforeEnter: pathGuard },
        { path: 'hrexcellence', name: "hrexcellence.bo", component: HRExcellence, beforeEnter: pathGuard },
        { path: 'hrexcellence/edit/:language', name: 'hr.edit', component: HrEdit, beforeEnter: pathGuard },
        /**
         * structure
         */
        { path: 'structure', name: "structure.bo", component: Structure, beforeEnter: pathGuard },
        { path: 'structure/person/edit/:id/:administration_id/:type', name: 'structure.person.edit', component: StructureEditPerson, beforeEnter: pathGuard },
        { path: 'structure/board/delete/:id/:type', name: 'structure.board.person.delete', component: StructureBoardDeletePerson, beforeEnter: pathGuard },
        { path: 'structure/board/add/:board_id/:type', name: 'structure.board.person.add', component: StructureBoardAddPerson, beforeEnter: pathGuard },
        { path: 'structure/board/order/:board_id/:type', name: 'structure.board.person.order', component: StructureBoardOrderPerson, beforeEnter: pathGuard },
        { path: 'structure/board/internal/order/:board_id/:type', name: 'structure.board.internal.order', component: StructureBoardOrderInternalPerson, beforeEnter: pathGuard },
        { path: 'structure/board/link/edit/:id', name: 'structure.board.link.edit', component: StructureBoardLink, beforeEnter: pathGuard },
        { path: 'structure/supervisory/edit/:id', name: 'structure.supervisory.edit', component: StructureEditSupervisoryPerson, beforeEnter: pathGuard },
        { path: 'structure/board/description/edit/:page/:board_id', name: 'structure.board.description.edit', component: StructureBoardDescription, beforeEnter: pathGuard },
        { path: 'contact', name: 'contact.bo', component: Contact, beforeEnter: pathGuard },
        { path: 'mission', name: 'mission.bo', component: Mission, beforeEnter: pathGuard },
        { path: 'mission/edit/:language', name: 'mission.edit', component: MissionEdit, beforeEnter: pathGuard },
        /**
         * DEPARTMENT
         */
        { path: 'department/items/:id', name: "department.bo", component: Department, beforeEnter: pathGuard },
        { path: 'department/archive/:id', name: "department.archive.bo", component: DepartmentArchive, beforeEnter: pathGuard },
        { path: 'department/edit/:id', name: "department.edit.bo", component: DepartmentEdit, beforeEnter: pathGuard },
        { path: 'department/add', name: "department.add.bo", component: DepartmentAdd, beforeEnter: pathGuard },
        { path: 'department/description/add/:department_id', name: "department.description.add.bo", component: DescriptionAdd, beforeEnter: pathGuard },
        { path: 'department/description/edit/:department_id', name: "department.description.edit.bo", component: DescriptionEdit, beforeEnter: pathGuard },
        { path: 'department/theme/add/:department_id/:sigle', name: "department.theme.add.bo", component: ThemeAdd, beforeEnter: pathGuard },
        { path: 'department/theme/edit/:id/:sigle', name: "department.theme.edit.bo", component: ThemeEdit, beforeEnter: pathGuard },
        { path: 'department/theme/delete/:id/:department_id', name: "department.theme.delete.bo", component: ThemeDelete, beforeEnter: pathGuard },
        { path: 'departments/manage', name: "departments.bo", component: Departments, beforeEnter: pathGuard },

        // // Library
        { path: 'esources', name: "esources.library.bo", component: ElectronicSources, beforeEnter: pathGuard },
        { path: 'asepriv', name: "asepriv.library.bo", component: LibraryAsepRiv, beforeEnter: pathGuard },
        { path: 'asep', name: "asep.library.bo", component: AsepEdit, beforeEnter: pathGuard },
        { path: 'riv', name: "riv.library.bo", component: RivEdit, beforeEnter: pathGuard },
        { path: 'rules', name: "edit.rules.library.bo", component: RulesEdit, beforeEnter: pathGuard },

        { path: 'library/home', name: "library.home.bo", component: LibraryHome, beforeEnter: pathGuard },
        { path: 'library/hours', name: "hours.library.bo", component: HoursEdit, beforeEnter: pathGuard },
        { path: 'library/message', name: "message.library.bo", component: MessageEdit, beforeEnter: pathGuard },
        { path: 'library/address', name: "address.library.bo", component: AddressEdit, beforeEnter: pathGuard },
        { path: 'library/rules', name: "rules.library.bo", component: LibraryRules, beforeEnter: pathGuard },
        { path: 'library/books', name: "books.library.bo", component: NewBooks, beforeEnter: pathGuard },
        { path: 'library/yearBooks/:year/:crud', name: "year.books.library.bo", component: NewBooksEdit, beforeEnter: pathGuard },
        { path: 'library/eresource/:id', name: "eresource.library.bo", component: EResourceEdit, beforeEnter: pathGuard },
        // //Journals
        { path: 'journal/1/:tab?/:issue?', name: "am.journal.bo", component: Journal, beforeEnter: pathGuard },
        { path: 'journal/2/:tab?/:issue?', name: "cmj.journal.bo", component: Journal, beforeEnter: pathGuard },
        { path: 'journal/3/:tab?/:issue?', name: "mb.journal.bo", component: Journal, beforeEnter: pathGuard },
        { path: 'zbmath/show/:tab?', name: "zbMath.journal.bo", component: ZbMath, beforeEnter: pathGuard },
        { path: 'zbmath/edit/:id', name: "zbMath.edit.bo", component: ZbMathEdit, beforeEnter: pathGuard },
        { path: 'journal/edit/:id/:model', name: "journal.edit.bo", component: JournalEdit, beforeEnter: pathGuard },
        { path: 'datajournal/edit/:id', name: "datajournal.edit.bo", component: DataJournalEdit, beforeEnter: pathGuard },
        { path: 'journaldescription/edit/:id', name: "journaldescription.edit.bo", component: JournalDescriptionEdit, beforeEnter: pathGuard },
        // //Grants
        { path: 'grants/running', name: "grant.running.bo", component: Grants, beforeEnter: pathGuard },
        { path: 'grants/past', name: "grant.past.bo", component: GrantsPast, beforeEnter: pathGuard },
        { path: 'grant/add', name: "grant.bo.add", component: GrantAdd, beforeEnter: pathGuard },
        { path: 'grant/edit/:id', name: "grant.bo.edit", component: GrantEdit, beforeEnter: pathGuard },
        { path: 'grant/view/:id', name: "grant.bo.view", component: GrantDetailView, beforeEnter: pathGuard },
        { path: 'grant/delete/:id', name: "grant.bo.delete", component: GrantDelete, beforeEnter: pathGuard },
        { path: 'grant/investigators/:id', name:"grant.bo.investigators",component: GrantInvestigators, beforeEnter: pathGuard },

        //GrantDetailView

        //VPN test
        { path: 'vpnerror', name: "vpnerror.bo", component: VPNError },
    ]
}];
