<template>
    <div :key="keyrand"
        align-end justify-end column
        class="ma-0 pb-0 ">
        <v-spacer></v-spacer>
        <ul class="nav nav-pills" >
            <li v-for="(itemMenu, index) in getMenu(bisMenu)"
                :class="['mr-0',{'dropup':itemMenu.id == idActiv},{'white':itemMenu.id == idActiv}]"
                :key="index"
                @click="setSubmenu(itemMenu)"
                @mouseleave="setSubmenu(0)">
                <v-btn v-if="itemMenu.dropdown"
                    :class="['mr-4','white--text','dropdown-toggle',{'blue-grey--text text--darken-3':itemMenu.id == idActiv}]"
                    text large style="text-transform:none"
                    >{{itemMenu.name[language][0]}}<br />{{itemMenu.name[language][1]}}
                    <span v-if="itemMenu.dropdown" :class="{dropup:itemMenu.id == idActiv}">
                        <span class="dropup-toggle"></span>
                    </span>
                </v-btn>
                <a  v-else :href="itemMenu.url" target="_blank" >
                    <v-btn class="mr-4 white--text"
                        text large style="text-transform:none" target="_blank" >
                        {{itemMenu.name[language][0]}}<br />{{itemMenu.name[language][1]}}
                    </v-btn>
                </a>
                <ul v-if="itemMenu.id == idActiv" class="menudown ml-0 pl-0" >
                    <a  v-for="(item, index) in items" :key="index" :href="item.url" >
                        <router-link
                            class="submenu ml-1 px-2 py-3 "
                            exact :style="{ cursor: 'pointer'}"
                            :to="item.url[language]" tag="li" active-class="active" >
                            <span class="blue--text text-body-2">{{item.name[language]}}</span>
                        </router-link>
                    </a>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {mapActions} from 'vuex';
export default{
  props: ['bisMenu','keyrand'],
  computed: {
    ...mapGetters('header',{
        items:'items',
        showItems:'showItems',
        idActiv:'idActiv',
        language:'language',
        departmentsItems:'departmentsItems',
    }),
  },
  methods:{
    ...mapActions('header',{
        setSubmenu: 'setSubmenu',
    }),
    getMenu(bm){
		bm.forEach(element => {
			if(element.id == 5){
				element.items = this.departmentsItems;
			}
			return element;
		});
		return bm;
    }
  }
}
</script>
