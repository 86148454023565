// store BO about/documents

const state = {
    departmentsName:[],
} ;
const getters = {
   departmentsName: state => {
        return state.departmentsName;
    },
};
const mutations = {
    'SET_DEPARTMENTS_NAME'(state,departmentsName){
        state.departmentsName = departmentsName;
    },
};
const actions = {
     async setDepartmentsName({ commit },active){
        let path = '/index.php/api/departments/names/'+active;
        try {
            const response = await axios.get(path);
            var departmentsName = response.data;
            commit('SET_DEPARTMENTS_NAME',departmentsName);
        } catch (error) {
            throw error;
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
