import Vue from 'vue';
import Vuex from 'vuex';

import header from './modules/header';
import news from './modules/news';
import publication from './modules/publication';
import grants from './modules/grants';
import talks from './modules/talks';
import researcher from './modules/researcher';
import visitors from './modules/visitors';
import seminars from './modules/seminars';
import calendar from './modules/calendar';
import library from './modules/library';
import structure from './modules/bo/structure';
import mission from './modules/bo/mission';
import hrexcellence from './modules/bo/hrexcellence';
import cooperation from './modules/bo/cooperation';
import documents from './modules/bo/documents';
import reports from './modules/bo/reports';
import formpublication from './modules/bo/formpublication';
import departments from './modules/bo/departments';
import formgrant from './modules/bo/formgrant';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        header,
        news,
        publication,
        grants,
        talks,
        researcher,
        visitors,
        seminars,
        calendar,
        library,
        structure,
        mission,
        hrexcellence,
        cooperation,
        documents,
        reports,
        formpublication,
        departments,
        formgrant,
    }
});

export default store;
