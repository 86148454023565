/**
 *
 * menu ABOUT
 */
const About = resolve => {
    require.ensure(['../../components/about/About.vue'], () => {
        resolve(require('../../components/about/About.vue'));
    });
};
const Contact = resolve => {
    require.ensure(['../../components/about/contact/Contact.vue'], () => {
        resolve(require('../../components/about/contact/Contact.vue'));
    });
};
const Documents = resolve => {
    require.ensure(['../../components/about/documents/Documents.vue'], () => {
        resolve(require('../../components/about/documents/Documents.vue'));
    });
};
const Reports = resolve => {
    require.ensure(['../../components/about/documents/Reports.vue'], () => {
        resolve(require('../../components/about/documents/Reports.vue'));
    });
};
const Mission = resolve => {
    require.ensure(['../../components/about/mission/Mission.vue'], () => {
        resolve(require('../../components/about/mission/Mission.vue'));
    });
};
const Structure = resolve => {
    require.ensure(['../../components/about/structure/Structure.vue'], () => {
        resolve(require('../../components/about/structure/Structure.vue'));
    });
};
const HRExcellence = resolve => {
    require.ensure(['../../components/about/hrexcellence/HRExcellence.vue'], () => {
        resolve(require('../../components/about/hrexcellence/HRExcellence.vue'));
    });
};
export const AboutAddresses = [{
    path: '/index.php/about',
    name: 'about',
    component: About,
    children: [
        { path: 'contact', name: 'contact', component: Contact },
        { path: 'documents', name: 'documents', component: Documents },
        { path: 'reports', name: 'reports', component: Reports },
        { path: 'mission', name: 'mission', component: Mission },
        { path: 'structure', name: 'structure', component: Structure },
        { path: 'hrexcellence', name: 'hrexcellence', component: HRExcellence },

    ]
}];
