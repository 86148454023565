const state={
    currentTabComponent:'AppHomeJournal',
    currentLibTabComponent:'AppHomeLibrary',
    bismenudown:false,
    journal:null,
    description:null,
    journalBis:null,
    showOneIssue:false,
    issue:{v:null,
        n:null,
        y:null},
    libraryRules:null,
};
const getters = {
    currentTabComponent: state => {
        return state.currentTabComponent;
    },
    currentLibTabComponent: state => {
        return state.currentLibTabComponent;
    },
    bismenudown:state => {
        return state.bismenudown;
    },
    journal:state =>{
        return state.journal;
    },
    description:state =>{
        return state.description;
    },
    journalBis:state =>{
        return state.journalBis;
    },
    showOneIssue:state => {
        return state.showOneIssue;
    },
    issue:state => {
        return state.issue;
    },
    libraryRules:state => {
        return state.libraryRules;
    }
};
const mutations = {
    'SET_CURRENT_TAB_COMPONENT' (state,currentTabComponent ) {
        state.currentTabComponent = currentTabComponent;
    },
    'SET_CURRENT_LIB_TAB_COMPONENT' (state,currentLibTabComponent ) {
        state.currentLibTabComponent = currentLibTabComponent;
    },
    'SET_BIS_MENU_DOWN' (state,bismenudown){
        state.bismenudown = bismenudown;
    },
    'SET_JOURNAL'(state,journal){
        state.journal = journal;
        if (!state.currentTabComponent) {
            state.currentTabComponent='AppHomeJournal';
        }
        
        state.bismenudown = false;
    },
    'SET_DESCRIPTION'(state,description){
        state.description = description;
    },
    'SET_JOURNAL_BIS'(state,journalbis){
        state.journalBis = journalbis;
    },
    'SET_SHOW_ONE_ISSUE'(state,show){
        state.showOneIssue = show;
    },
    'SET_ISSUE'(state,issue){
        state.issue = issue;
    },
    'SET_LIBRARY_RULES'(state,rules){
        state.libraryRules = rules;
    }
};
const actions = {
    setCurrentTabComponent ({ commit }, currentTabComponent){
        commit('SET_CURRENT_TAB_COMPONENT', currentTabComponent);
    },
    setCurrentLibTabComponent ({ commit }, currentLibTabComponent){
        commit('SET_CURRENT_LIB_TAB_COMPONENT', currentLibTabComponent);
    },
    setBismenudown ({commit}, bismenudown){
        commit('SET_BIS_MENU_DOWN',bismenudown);
    },
    async setJournal ({commit}, journal_id){
         const response = await axios.get(
            '/index.php/api/library/journals/journal/'+journal_id
        );
        const payload = response.data;
        commit('SET_JOURNAL', payload);
    },
    async setDescription ({commit}, journal_id){
         const response = await axios.get(
            '/index.php/api/library/journalbis/description/'+journal_id
        );
        const payload = response.data;
        commit('SET_DESCRIPTION', payload);
    },
    async setJournalBis ({commit}, journal_id){
         const response = await axios.get(
            '/index.php/api/library/journals/journalbis/'+journal_id
        );
        const payload = response.data;
        commit('SET_JOURNAL_BIS', payload);
    },
    setShowOneIssue({commit},show){
        commit('SET_SHOW_ONE_ISSUE',show);
    },
    setIssue({commit},issue){
        commit('SET_ISSUE',issue);
    },
    setLibraryRules({commit},rules){
        commit('SET_LIBRARY_RULES',rules);
    }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
