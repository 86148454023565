import router from '../../router';
const state = {
    vpn : false,
    items: [],
    showItems: false,
    idActiv: 0,
    drawer: false,
    mdAndDown: false,
    smAndDown: false,
    mobile:false,
    width:0,
    xsOnly:false,
    showBisMenuDown: false,
    showMainMenuDown: false,
    departmentsItems:[],
    language: 'en',
    headerImage:'MU_en4_Big.jpg',
    footerCASimage:'av_en.png',
    footerCMSimage:'CMS_logo_ENG_negativ.png',
    footerIntitute:'Institute of Mathematics CAS',
    footerRep:'Czech Republic',
    maxHeight:"90",
    maxWidth:"297",
    nameRoute:null,
    hiddenHeader:false,
    authenticated:false,
    useractive:{ id:0,
        name:null,
        active:0,
        permissions:[],
        person_id:0,
        last_name:null,
        first_name:null,
        category_id:null,
    },
};
const getters = {
    vpn: state => {
        return state.vpn;
    },
    items: state => {
        return state.items;
    },
    showItems: state => {
        return state.showItems;
    },
    idActiv: state => {
        return state.idActiv;
    },
    drawer: state => {
        return state.drawer;
    },
    mdAndDown: state => {
        return state.mdAndDown;
    },
    smAndDown: state => {
        return state.smAndDown;
    },
    mobile: state => {
        return state.mobile;
    },
    width: state => {
        return state.width;
    },
    xsOnly: state => {
        return state.xsOnly;
    },
    showBisMenuDown: state => {
        return state.showBisMenuDown;
    },
    showMainMenuDown: state => {
        return state.showMainMenuDown;
    },
    departmentsItems: state => {
        return state.departmentsItems;
    },
    language: state => {
        return state.language;
    },
    headerImage: state => {
        return state.headerImage;
    },
    footerCASimage:state => {
        return state.footerCASimage;
    },
    footerCMSimage:state => {
        return state.footerCMSimage;
    },
    footerIntitute:state => {
        return state.footerIntitute;
    },
    footerRep: state => {
        return state.footerRep
    },
    maxWidth: state => {
        return state.maxWidth;
    },
    maxHeight: state => {
        return state.maxHeight;
    },
    nameRoute: state => {
        return state.nameRoute;
    },
    useractive: state => {
        return state.useractive;
    },
    authenticated(state){
        return state.authenticated
    },
    hiddenHeader:state => {
        return state.hiddenHeader;
    }
};
const mutations = {
    'SET_VPN'(state, val) {
        state.vpn = val;
    },
     'TOGGLE_LANGUAGE' (state,payload) {
         /**
          * CZ language
          */
        if (state.language === 'en'){
            state.language = 'cz';
            state.footerCASimage = 'av_cz.png';
            state.footerCMSimage = 'CMS_logo_CZ_negativ.png';
            state.footerIntitute = ' Matematický ústav AV ČR';
            state.footerRep = 'Česká republika';
            if(state.width > 0 && state.width <= 331){
                state.headerImage ='MU_cz4_XSmall.jpg';
            }
            else if (state.width > 331 && state.width < 600) {
                state.headerImage = 'MU_cz3_Small.jpg';
            } else if(state.width >= 600){
                state.headerImage = 'MU_cz1_Big.jpg';
            }
        }
        /**
         *  EN language
         */
        else {
            state.language = 'en';
            state.footerCASimage = 'av_en.png';
            state.footerCMSimage = 'CMS_logo_ENG_negativ.png';
            state.footerIntitute = 'Institute of Mathematics CAS';
            state.footerRep = 'Czech Republic';
            if(state.width > 0 && state.width <= 331){
                state.headerImage ='MU_en4_XSmall.jpg';
            }
            else if (state.width > 331 && state.width < 600) {
                state.headerImage = 'MU_en3_Small.jpg';
            } else if(state.width >= 600){
                state.headerImage = 'MU_en4_Big.jpg';
            }
        }
    },
    'SET_SUBMENU' (state, menu) {
        // for even onleave menu the mutation receive payload menu = 0
        if(menu == 0){
            state.items = [];
            state.idActiv = 0;
            state.showItems = false;
            return;
        }
        // for even onclick on menu the mutation receive payload menu = object with items
        state.showItems = !state.showItems;
        if (state.showItems) {
            state.items = menu.items;
            state.idActiv = menu.id;
        } else if (state.idActiv == menu.id) {
            state.items = [];
            state.idActiv = 0;
        } else {
            state.showItems = !state.showItems;
            state.items = menu.items;
            state.idActiv = menu.id;
        }
    },
    'SET_MD_AND_DOWN' (state, mdAndDown) {
        state.mdAndDown = mdAndDown;
    },
    'SET_SM_AND_DOWN' (state, smAndDown) {
        state.smAndDown = smAndDown;
    },
    'SET_MOBILE'(state, mobile) {
        state.mobile = mobile;
    },
    'SET_WIDTH'(state, width) {
        state.width = width;
        if(state.language == 'en'){
            if(width > 0 && width <= 331){
                state.headerImage ='MU_en4_XSmall.jpg';
                state.maxHeight = 70;
                state.maxWidth = 188;
            }
            else if (width > 331 && width < 600) {
                state.headerImage = 'MU_en3_Small.jpg';
                state.maxHeight = 70;
                state.maxWidth = 231;

            } else if(width >= 600){
                state.headerImage = 'MU_en4_Big.jpg';
                state.maxHeight = 90;
                state.maxWidth = 297;
            }
        }
        else{
            if(width > 0 && width <= 331){
                state.headerImage ='MU_cz4_XSmall.jpg';
                state.maxHeight = 70;
                state.maxWidth = 188;
            }
            else if (width > 331 && width < 600) {
                state.headerImage = 'MU_cz3_Small.jpg';
                state.maxHeight = 70;
                state.maxWidth = 231;

            } else if(width >= 600){
                state.headerImage = 'MU_cz1_Big.jpg';
                state.maxHeight = 90;
                state.maxWidth = 297;
            }
        }
    },
    'SET_XS_ONLY' (state, xsOnly) {
        state.xsOnly = xsOnly;
    },

    // toggle drawer, toggle sandwich menu
    'TOGGLE_DRAWER' (state) {
        state.drawer = !state.drawer;
        if (state.drawer) {
            state.showBisMenuDown = true;
            state.showMainMenuDown = true;
        } else {
            state.showBisMenuDown = false;
            state.showMainMenuDown = false;
        }
    },
    'SET_NAME_ROUTE'(state, nameRoute){
        state.nameRoute = nameRoute;
    },
    'SET_USER_ACTIVE'(state, userActive){
        state.useractive = {
            id:userActive.id,
            name:userActive.name,
            active:userActive.active,
            permissions:userActive.permissions,
            person_id:userActive.person_id,
            category_id:userActive.category_id,
            first_name:userActive.first_name,
            last_name:userActive.last_name,
        };
    },
    SET_AUTHENTICATED (state, value) {
            state.authenticated = value
    },
    'SET_HIDDEN_HEADER'(state, showhiddden){
        state.hiddenHeader = showhiddden;
    },
    'SET_DEPARTMENTS_ITEMS'(state,departmentsItems){
        state.departmentsItems = departmentsItems;
    }

};
const actions = {
    checkVPN: ({ commit }) => {
        axios.delete('/index.php/api/vpntest').then(() => {
            commit('SET_VPN', true);
        }, (error) => {
            commit('SET_VPN', false);
        });
    },
    setSubmenu: ({ commit }, menu) => {
        commit('SET_SUBMENU', menu);
    },
    set_MdAndDown({ commit }, mdAndDown) {
        commit('SET_MD_AND_DOWN', mdAndDown);
    },
    set_SmAndDown({ commit }, smAndDown) {
        commit('SET_SM_AND_DOWN', smAndDown);
    },
    set_mobile({ commit }, mobile) {
        commit('SET_MOBILE', mobile);
    },
    set_width({ commit }, width) {
        state.showBisMenuDown = false;
        state.showMainMenuDown = false;
        state.drawer = false;
        commit('SET_SUBMENU', 0);
        commit('SET_WIDTH', width);
    },

    set_xsOnly({ commit }, xsOnly) {
        commit('SET_XS_ONLY', xsOnly);
    },
    toggleDrawer: ({ commit }) => {
        commit('SET_SUBMENU', 0);
        commit('TOGGLE_DRAWER');
    },
    toggleLanguage: ({ commit }, payload) => {
        commit('TOGGLE_LANGUAGE', payload);
        payload._vm.locale = state.language;
        payload._vm.$root.locale = state.language;
        payload._vm.fallbackLocale= state.language;
        payload._vm.$root.fallbackLocale = state.language;
    },
    set_NameRoute: ({ commit }, payload) => {
        commit('SET_NAME_ROUTE', payload);
    },
    async setDepartmentsItems({commit}){
        const response = await axios.get('/index.php/api/menu/departments');
        const payload = response.data;
        commit('SET_DEPARTMENTS_ITEMS', payload);
    },
    async set_userActive({commit},stateLogin) {
        var userActive = {id:0,
            name:null,
            active:0,
            permissions:[],
            person_id:0,
            last_name:null,
            first_name:null,
            category_id:null,
        };
        //stateLogin 0 for logout, 1 for login
        if(stateLogin == 0){
            commit('SET_AUTHENTICATED',false);
            commit('SET_USER_ACTIVE',userActive);
        }
        else{
            await axios.get('/index.php/api/user'
                ,{validateStatus: function (status) {
                        return status < 400;
                    }
            }).then(({data})=>{
                commit('SET_AUTHENTICATED',true);
                userActive = { id: data.id,
                    name:data.name,
                    active:1,
                };
            }).catch((response)=>{
                if(response.status == 401){
                    router.push({name:'userlogin'})
                }
            })
            .catch(function (error) {
                console.log(error.toJSON());
            });
            if(userActive.id > 0){
                const permissionsData = await axios.get('/index.php/api/bo/user/'+userActive.id+'/permission',{
                    validateStatus: function (status) {
                        return status < 500;
                    }
                });
                userActive.permissions = permissionsData.data.permissionsUser;
                userActive.person_id = permissionsData.data.person_id;
                userActive.category_id = permissionsData.data.category_id;
                userActive.first_name=permissionsData.data.first_name;
                userActive.last_name = permissionsData.data.last_name;
                commit('SET_USER_ACTIVE',userActive);

            }
        }
    },
    set_hiddenHeader:({ commit }, payload) => {
        commit('SET_HIDDEN_HEADER', payload);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
