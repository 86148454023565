import axios from 'axios';
import typePublication from '../../data/research/typePublication.js';
import typePublicationAsep from '../../data/research/typePublicationAsep.js';
import typePublicationCz from '../../data/research/typePublicationCz.js';
import typePublicationAsepCz from '../../data/research/typePublicationAsepCz.js';

const state = {
    id_member: 0,
    department: 0,
    departments: [],
    members:[],
    id_investigator:0,
    grant:0,
    grants:[],
    investigators:[],
    type: 20,
    year: 1,
    asepStatus:100,  // no asep filter
    listTypePublication: [],
    typePublication: typePublication,
    typePublicationCz: typePublicationCz,
    typePublicationAsep: typePublicationAsep,
    typePublicationAsepCz: typePublicationAsepCz,
    showDepartments:true,
    publications:[],
    lecturesOnly:[],
    morePublications:[],
    typeMore:20,
    yearMore:1,
    matches:0,
    showMatches:true,
    showMore:0,
    loading:false,
    nbBlocks:0,
};
const getters = {
    id_member: state => {
        return state.id_member;
    },
    department: state => {
        return state.department;
    },
    departments: state => {
        return state.departments;
    },
    members: state => {
        return state.members;
    },
    id_investigator: state => {
        return state.id_investigator;
    },
    grant: state => {
        return state.grant;
    },
    grants: state => {
        return state.grants;
    },
    investigators: state => {
        return state.investigators;
    },
    type: state => {
        return state.type;
    },
    year: state => {
        return state.year;
    },
    typeMore: state => {
        return state.typeMore;
    },
    yearMore: state => {
        return state.yearMore;
    },
    typePublication: state => {
        return state.typePublication;
    },
    typePublicationCz: state => {
        return state.typePublicationCz;
    },
    typePublicationAsep: state => {
        return state.typePublicationAsep;
    },
    typePublicationAsepCz: state => {
        return state.typePublicationAsepCz;
    },
    asepStatus: state => {
        return state.asepStatus;
    },
    listTypePublication: state => {
        state.typePublication.forEach(element => {
            if(element.id)
                state.listTypePublication[element.id] = element.name;
        });
        return state.listTypePublication;
    },
    showDepartments: state => {
        return state.showDepartments;
    },
    publications: state => {
        return state.publications;
    },
    lecturesOnly: state => {
        return state.lecturesOnly;
    },
    morePublications: state => {
        return state.morePublications;
    },
    matches: state =>{
        return state.matches;
    },
    showMatches: state =>{
        return state.showMatches;
    },
    showMore: state => {
        return state.showMore;
    },
    loading:state =>{
        return state.loading;
    },
    nbBlocks:state =>{
        return state.nbBlocks;
    },
};
const mutations = {
    'SET_PUBLICATIONS_INIT'(state){
        // state.publications = [];  // ??????????
        state.morePublications = [];
        state.matches = 0;
        state.showMatches = true;
        state.loading = true;
        state.nbBlocks = 0;
        state.showMore =0;
        state.grant = 0;
        state.asepStatus = 1;
    },
    'SET_PUBLICATIONS' (state, payload) {
        var nbFirstPart = 5;
        // lectures on the grant, for page detail of grant
        if(payload.criterias.selectedType == 8 ){ 
            // && (payload.criterias.selectedGrant > 0 || payload.criterias.selectedMember)
            state.lecturesOnly = payload.publications;
        }
        else{   
            state.publications = payload.publications;
            state.matches = payload.matches;
            state.typeMore = payload.criterias.selectedType;
            state.yearMore = payload.criterias.selectedYear;
        }
          
        // state.matches = payload.matches;
        state.asepStatus = payload.criterias.asepStatus;
        if(payload.criterias.selectedType != 8 ){ 
            if(state.year != 1 ){
                nbFirstPart = 50;
            }
            state.nbBlocks = Math.max(0, Math.ceil((payload.matches - nbFirstPart) / 50));
            if(payload.matches > nbFirstPart){
                state.showMore = 1;
            }
            // state.showMatches = true;  
        }  
        state.type = payload.criterias.selectedType;
        state.year = payload.criterias.selectedYear;

        if(state.year == 1 )
            state.showMatches = false;
        if(payload.criterias.selectedDepartment){
            state.department = payload.criterias.selectedDepartment;
        }

        if(state.showDepartments){
            state.department = payload.criterias.selectedDepartment;
            state.id_member = payload.criterias.selectedMember;
        }
        else{
            state.grant = payload.criterias.selectedGrant;
            state.id_investigator = payload.criterias.selectedInvestigator;
        }
        state.loading = false;

    },
    'SET_SHOW_MATCHES'(state, payload) {
        state.showMatches = payload;

    },
    'SET_MORE_PUBLICATIONS'(state, payload) {
        state.morePublications.push(payload.morePublications);
        state.loading = false;

    },
    'SET_DEPARTMENT'(state,department){
        state.department = department;
    },
    'SET_SHOW_DEPARTMETN'(state,payload){
        state.showDepartments = payload;
    },
    'SET_DEPARTMENTS_YEAR' (state, payload ) {
        state.year = payload.year;
        state.departments = payload.departmentsData;
    },
    'SET_YEAR' (state, year) {
        state.year = year;
    },
    'SET_GRANT' (state, grant) {
        state.grant = grant;
    },
    'SET_MEMBERS' (state, payload ) {
        state.year = payload.year;
        state.department = payload.department;
        state.members = payload.members;
    },
    'SET_GRANTS_YEAR'(state, payload ) {
        state.year = payload.year;
        state.grants = payload.grantsData;
    },
    'SET_GRANTS_YEAR1'(state, payload ) {
        state.year = payload.year;
        state.grants = payload.grantsData;
    },
    'SET_INVESTIGATORS'(state, payload ) {
      state.grant = payload.grant;
      state.investigators = payload.investigators;
    },
    'SET_INVESTIGATORS1'(state, payload ) {
      state.grant = payload.grant;
      state.investigators = payload.investigators;
    },
};
const actions = {
    setDepartment({ commit},idDepartment){
        commit('SET_DEPARTMENT',idDepartment);
    },
    async setPublications ({ commit,state }, criterias, ){
        //only for publications, never for lectures
        if(criterias.selectedType != 8){
            commit('SET_PUBLICATIONS_INIT');
        }
        
        var publications_path = '/index.php/api/';
        if(state.showDepartments){
            publications_path = publications_path + 'publicationsDepartments/' + criterias.selectedDepartment+'/'+criterias.selectedMember;
        }
        else{
            publications_path = publications_path + 'publicationsGrants/'+criterias.selectedGrant+'/'+criterias.selectedInvestigator;
        }
        publications_path = publications_path +'/'+criterias.selectedYear+'/'+criterias.selectedType+'/';

        //only for publications on Grants owned by the department
        if(!state.showDepartments){
            publications_path = publications_path + criterias.idDepartmentGrants+'/';
        }
        if(state.showDepartments){
            //asep status
            if (typeof criterias.asepStatus !== 'undefined') {
                publications_path = publications_path + criterias.asepStatus+'/';
            }
            // no asep status criteria
            else{
                publications_path = publications_path + '100/'
            }
        }
        
        //more part of publication
        publications_path = publications_path + '0';

        const response = await axios.get(publications_path);
        const payload = {'criterias':criterias,'publications': response.data['publications'], 'matches': response.data['matches']};
        commit('SET_PUBLICATIONS', payload);
    },
     async setMorePublications ({ commit,state }, index ){
        var publications_path = '/index.php/api/';
        if(state.showDepartments)
            publications_path = publications_path + 'publicationsDepartments/' + state.department+'/'+state.id_member;
        else
            publications_path = publications_path + 'publicationsGrants/'+state.grant+'/'+state.id_investigator;
        publications_path = publications_path +'/'+state.yearMore+'/'+state.typeMore+'/';

        //only for publications on Grants owned by the department
        if(!state.showDepartments){
            publications_path = publications_path + state.department+'/';
        }
        //asep status
        if(state.showDepartments){
            if (typeof state.asepStatus !== 'undefined') {
                publications_path = publications_path + state.asepStatus+'/';
             }
             // no asep staus criteria
             else{
                publications_path = publications_path + '100/'
             }
        }
        
        //more part of publication
        // index = 0 get first block of more data of publicationsS
        var more = Number(index) + Number(1)
        publications_path = publications_path + more;

        const response = await axios.get(publications_path);
        const payload = {'index':index,'morePublications': response.data['publications']};
        commit('SET_MORE_PUBLICATIONS', payload);
    },
    setShowDepartments({commit},payload){
        commit('SET_SHOW_DEPARTMETN',payload);
    },
    async setDepartmentsYear({ commit }, year){
        const response = await axios.get(
            '/index.php/api/departments/'+year
        );
        const payload = {'year': year, 'departmentsData': response.data};
        commit('SET_DEPARTMENTS_YEAR',payload);
    },
    async setMembers({commit},[year,department,all]){
        const response = await axios.get(
            '/index.php/api/members/'+year+'/'+department+'/'+all
        );
        const payload = {'year': year, 'department': department,'members': response.data};
        commit('SET_MEMBERS',payload);
    },
    async setGrantsYear({ commit }, [year,all]){
        const response = await axios.get(
            '/index.php/api/grants/list/'+year+'/'+all
        );
        const payload = {'year': year, 'grantsData': response.data};
        commit('SET_GRANTS_YEAR',payload);
    },

    async setDepartmentGrantsYear({commit},[year,department]){
        var grants_path =  '/index.php/api/department/grants/'+year+'/'+department;
        const response = await axios.get(grants_path);
        const payload = {'year': year, 'grantsData': response.data};
        commit('SET_GRANTS_YEAR1',payload);
    },

    async setInvestigators({commit},[grant,year]){
        const response = await axios.get(
            '/index.php/api/investigators/'+grant+'/'+year
        );
        const payload = {'grant': grant,'investigators': response.data};
        commit('SET_INVESTIGATORS',payload);
    },

    async setInvestigatorsInDepartment({commit},[grant,year,department]){
        const response = await axios.get(
            '/index.php/api/department/investigators/'+grant+'/'+year+'/'+department
        );
        const payload = {'grant': grant,'investigators': response.data};
        commit('SET_INVESTIGATORS1',payload);
    },
    setYear({ commit }, year){
        commit('SET_GRANTS_YEAR',year);
    },
    setShowMatches({ commit }, payload){
        commit('SET_SHOW_MATCHES',payload);
    },

};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
