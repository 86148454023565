export default [
    /** MAIN menu
     */
    {
        id: 1,
        type: 'main',
        name: {'en':'People', 'cz':'Lidé'},
        dropdown: true,
        url: null,
        items: [
            { url: '/index.php/members/researchers', name: {'en':'Research fellows','cz':'Vědečtí pracovníci'} },
            { url: '/index.php/members/postdocs', name: {'en':'Postdocs, students','cz': 'Postdoktorandi, studenti'} },
            { url: '/index.php/members/staff', name: {'en':'Staff','cz': 'Ostatní'} },
            { url: '/index.php/members/distinguishedVisitors', name: {'en':'Eduard Čech Distinguished Visitor', 'cz':'Eduard Čech Distinguished Visitor'} },
            { url: '/index.php/members/visitors/2020', name: {'en':'Visitors','cz': 'Hostující pracovníci'} }
        ],
    },
    {
        id: 2,
        type: 'main',
        name: {'en':'Research','cz': 'Výzkum'},
        dropdown: true,
        url: null,
        items: [
            { url: '/index.php/research/publications', name: {'en':'Publications','cz': 'Publikace'} },
            { url: '/index.php/research/lectures', name: {'en':'Lectures','cz': 'Přednášky'} },
            { url: '/index.php/research/collaboration', name: {'en':'Collaboration', 'cz':'Spolupráce'} },
        ]
    },
    {
        id: 3,
        type: 'main',
        name: {'en':'Events', 'cz':'Vědecké akce'},
        dropdown: true,
        url: null,
        items: [
            { url: '/index.php/events/calendar', name: {'en':'Calendar', 'cz':'Kalendář akcí'} },
            { url: '/index.php/events/seminars', name: {'en':'Seminars', 'cz':'Semináře'} },
            { url: '/index.php/events/conferences', name: {'en':'Conferences','cz': 'Konference'} },
            { url: '/index.php/events/lecturesCech', name: {'en':'Eduard Čech Lectures','cz': 'Čechovské přednášky'} },
            { url: '/index.php/events/awards', name: {'en':'Awards', 'cz':'Ocenění'} },
        ],
    },
    {
        id: 4,
        type: 'main',
        name: {'en':'About', 'cz':'O nás'},
        dropdown: true,
        url: null,
        items: [
            // { url: '/index.php/', name: {'en':'News','cz': 'Aktuality'} },
            { url: '/index.php/about/mission', name: {'en':'Mission', 'cz':'Poslání'} },
            { url: '/index.php/about/structure', name: {'en':'Structure','cz': 'Struktura'} },
            { url: '/index.php/about/HRExcellence', name: {'en':'HR Excellence','cz': 'HR Excellence'} },
            { url: '/index.php/about/reports', name: {'en':'Annual reports','cz': 'Výroční zprávy'} },
            { url: '/index.php/about/documents', name: {'en':'Documents','cz': 'Dokumenty'} },
            { url: '/index.php/about/contact', name: {'en':'Contact', 'cz':'Kontakt'} },
        ],
    },
    /** BIS menu
     */
    {
        id: 5,
        type: 'bis',
        name: {
            'en':{'0':'Departments'},
            'cz':{'0':'Oddělení'},
        },
        items: [{
                    url: {'en':'/index.php/department/detail/14',
                        'cz':'/index.php/department/detail/14'
                    },
                    name: {'en':'Abstract Analysis (AA)',
                        'cz':'Abstraktní analýza (AA)'
                    }
                },
                {
                    url: {'en':'/index.php/department/detail/18',
                        'cz':'/index.php/department/detail/18'
                    },
                    name: {'en':'Algebra, Geometry and Mathematical Physics (AGMP)',
                        'cz':'Algebra, geometrie a matematická fyzika (AGMP)'
                    }
                },
                {
                    url: {'en':'/index.php/department/detail/13',
                        'cz':'/index.php/department/detail/15'
                    },
                    name: {'en':'Constructive Methods of Mathematical Analysis (CMMA)',
                            'cz':'Evoluční diferenciální rovnice (EDE)'
                    }
                },
                {
                    url: {'en':'/index.php/department/detail/15',
                        'cz':'/index.php/department/detail/13'
                    },
                    name: {'en':'Evolution Differential Equations (EDE)',
                        'cz':'Konstruktivní metody matematické analýzy (CMMA)',
                    }
                },
                {
                    url: {'en':'/index.php/department/detail/16',
                        'cz':'/index.php/department/detail/16'
                    },
                    name: {'en':'Mathematical Logic and Theoretical Computer Science (MLTCS)',
                        'cz':'Matematická logika a teoretická informatika (MLTCS)'
                    }
                },
        ],
        dropdown: true,
        url: null
    },
    {
        id: 6,
        type: 'bis',
        name: {
            'en':{'0':'Library'},
            'cz':{'0':'Knihovna'},
        },
        items: [
            {
                url: {'en':'/index.php/library/home', 'cz':'/index.php/library/home'},
                name: {'en':'Library of the Institute', 'cz':'Knihovna ústavu'}
            },
            {
                url:{'en': '/index.php/library/journal/1/home','cz':'/index.php/library/journal/1/home'},
                name: {'en':'Applications of Mathematics', 'cz':'Applications of Mathematics'}
            },
            {
                url: {'en': '/index.php/library/journal/2/home', 'cz':'/index.php/library/journal/2/home'},
                name: {'en':'Czechoslovak Mathematical Journal', 'cz':'Czechoslovak Mathematical Journal'} },
            {
                url: {'en': '/index.php/library/journal/3/home','cz':'/index.php/library/journal/3/home' },
                name: {'en':'Mathematica Bohemica','cz': 'Mathematica Bohemica'}
            },
            {
                url: {'en': '/index.php/higher','cz':'/index.php/higher' },
                name: {'en':'Higher Structures','cz': 'Higher Structures'}
            },
            {
                url: {'en': '/index.php/library/zbmath/about','cz':'/index.php/library/zbmath/about' },
                name: {'en':'zbMATH Open','cz': 'zbMATH Open'}
            },

        ],
        dropdown: true,
        url: null
    },

    {
        id: 7,
        type: 'bis',
        name: {
            'en':{
                '0':'Grants',
            },
            'cz':{
                '0':'Granty,',
                '1':'projekty'
            },
        },

        items: [
            {
                url: {'en':'/index.php/grants/running/1', 'cz':'/index.php/grants/running/1'},
                name: {'en':'Running', 'cz':'Probíhající'} },
            {
                url: {'en':'/index.php/grants/past/0', 'cz':'/index.php/grants/past/0'},
                name: {'en':'Past', 'cz':'Ukončené'} },
        ],
        dropdown: true,
        url: null
    },
    {
        id: 8,
        type: 'bis',
        name: {
            'en':{
                '0':'Positions',
            },
            'cz':{
                '0':'Pracovní ',
                '1':'přílezitosti'
            },
        },
        dropdown: false,
        url: '/index.php/positions'
    },
];
