<template>
<div>
    <v-row class="hidden-sm-and-up basic ma-0 pa-0">
        <v-col cols="12" col-md-6 class="ma-0 pa-0"
            v-for="(itemMenu, index) in bisMenu"
            :class="['menudown',{'white':itemMenu.id == idActiv}]"
            :key="index"
            @click="setSubmenu(itemMenu)">
            <v-btn v-if="itemMenu.dropdown" text x-large
                :class="['white--text','dropdown-toggle',{'blue-grey--text text--darken-3':itemMenu.id == idActiv}]"
                style="text-transform: none !important;">
                {{itemMenu.name[language][0]}} {{itemMenu.name[language][1]}}
                <span v-if="itemMenu.dropdown" :class="{dropup:itemMenu.id == idActiv}">
                <span class="dropup-toggle"></span>
                </span>
            </v-btn>
            <v-btn v-else
                text x-large
                :class="['ml-6','px-0','white--text','basic']" style="border-style: hidden; text-transform: none !important;"
                :to="itemMenu.url" >
                {{itemMenu.name[language][0]}} {{itemMenu.name[language][1]}}
            </v-btn>
            <ul v-if="itemMenu.id == idActiv" @click="toggleDrawer()" class="ml-0 pl-0">
                <router-link
                    v-for="(item, index) in items" :key="index"
                    class="submenu mx-0 px-0 py-0 my-0"
                    :to="item.url[language]" tag="li" active-class="active" >
                    <p class="blue--text my-0 ml-4 pl-2 py-3">{{item.name[language]}}</p>
                </router-link>
            </ul>
        </v-col>
    </v-row>
    <v-row justify="space-around" class="d-none d-sm-flex d-md-none basic">
        <div class="ma-0 pa-0"
            v-for="(itemMenu, index) in bisMenu"
            :class="['menudown',{'white':itemMenu.id == idActiv}]"
            :key="index"
            @click="setSubmenu(itemMenu)">
            <v-btn v-if="itemMenu.dropdown" text x-large
                :class="['white--text','dropdown-toggle',{'blue-grey--text text--darken-3':itemMenu.id == idActiv},]"
                style="text-transform: none !important;">
                {{itemMenu.name[language][0]}} {{itemMenu.name[language][1]}}
                <span v-if="itemMenu.dropdown" :class="{dropup:itemMenu.id == idActiv}">
                <span class="dropup-toggle"></span>
                </span>
            </v-btn>
            <v-btn v-else
                text x-large
                :class="['px-0','white--text','basic',{'mr-8':index ==3} ]" style="border-style: hidden; text-transform: none !important;"
                :to="itemMenu.url" >
                {{itemMenu.name[language][0]}} {{itemMenu.name[language][1]}}
            </v-btn>
            <ul v-if="itemMenu.id == idActiv" @click="toggleDrawer()" class="ml-0 pl-0 menudown-line">
                <router-link
                    v-for="(item, index) in items" :key="index"
                    class="submenu mx-0 px-0 py-0 my-0"
                    :to="item.url[language]" tag="li" active-class="active" >
                    <p class="blue--text my-0 ml-4 pl-2 py-3">{{item.name[language]}}</p>
                </router-link>
            </ul>
        </div>
    </v-row>
</div>
</template>
<script>
import {mapGetters} from 'vuex';
import {mapActions} from 'vuex';

export default{
  props: ['bisMenu'],
  computed: {
    ...mapGetters('header',{
        items:'items',
        showItems:'showItems',
        idActiv:'idActiv',
        language:'language',
    })
  },
  methods:{
    ...mapActions('header',{
        setSubmenu: 'setSubmenu',
        toggleDrawer:'toggleDrawer',
    }),
  }
}
</script>
<style scoped>
.menudown-line {
    position: absolute;
    z-index: 1000;
    background-color: white;
    width: 100%;
    min-width: 220px;
    max-width: 260px;
    border-bottom-style: solid ;
    border-bottom-width: 1px;
    border-bottom-color: #2196F3;
    border-left-style: solid ;
    border-left-width: 1px;
    border-left-color: #2196F3;
    border-right-style: solid ;
    border-right-width: 1px;
    border-right-color: #2196F3;
}
</style>


