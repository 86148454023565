const state = {
    year: new Date().getFullYear() - 2,
    grants: [],
    more:1,
    progressloading:true,
    grantslength:-1
};
const getters = {
    year: state => {
        return state.year;
    },
    grants: state => {
        return state.grants;
    },
    more: state => {
        return state.more;
    },
    progressloading: state => {
        return state.progressloading;
    },
    grantslength: state => {
        return state.grantslength;
    }
};
const mutations = {
    'SET_GRANTS_INFOS' (state, payload) {
        state.year =  payload.year;
        state.grants = payload.grantsInfosData;
        state.grantslength = payload.grantsInfosData.length;

    },
    'SET_GRANTS_ALL' (state, payload) {
        state.grants = payload.grantsData;
    },
    'SET_MORE'(state, payload) {
        state.more = payload;
    },
    'SET_PROGRESSLOADING'(state, payload) {
        state.progressloading = payload;
    },
};
const actions = {
    async setGrantsInfos({ commit }, [active,year]){
        commit('SET_PROGRESSLOADING',true);
        const response = await axios.get(
            '/index.php/api/grantsInfo/list/'+active+'/'+year
        );
        const payload = {'year': year, 'grantsInfosData': response.data};
        if(response){
             commit('SET_PROGRESSLOADING',false);
        }
        commit('SET_GRANTS_INFOS',payload);
    },
    async setGrantsAll({ commit }, active){
        const response = await axios.get(
            '/index.php/api/grantsInfo/all/'+active
        );
        const payload = {'grantsData': response.data};
        commit('SET_GRANTS_ALL',payload);
    },
    async setInvestigatorGrants({ commit }, [id,active,]){
        const response = await axios.get(
            '/index.php/api/grantsInfo/investigator/'+id+'/'+active
        );
        const payload = {'year': 0, 'grantsInfosData': response.data};
        commit('SET_GRANTS_INFOS',payload);
    },
    setMore({ commit },more){
       commit('SET_MORE',more);
    },
    incMore({commit}){
        var more = state.more +1;
        commit('SET_MORE',more);
    },
    setProgressloading({ commit },progress){
       commit('SET_PROGRESSLOADING',progress);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
