require('./bootstrap');
import Vue from 'vue'
import VueResource from 'vue-resource';
import VueEditor from 'vue2-editor';

import App from './components/App.vue';
import router from './router/index.js';
import VueGtag from 'vue-gtag';
import VueCookies from 'vue-cookies'

import store from './store/store';
import { i18n } from './plugins/i18n/i18n';
import vuetify from './plugins/vuetify';

import 'leaflet/dist/leaflet.css';

import dayjs from 'dayjs';

// TODO: comment this to support vee-validate3
// import VeeValidate from 'vee-validate';
// Vue.use(VeeValidate);

// TODO: uncomment this to support vee-validate3
import { extend, localize } from "vee-validate";
import { min, max, alpha, numeric, min_value, max_value, between, required, email } from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";

// // Install rules
extend("required", required);
extend("min", min);
extend("max", max);
extend("alpha", alpha);
extend("numeric", numeric);
extend("min_value", min_value);
extend("max_value", max_value);
extend("between", between);
extend('url', {
  validate(value) {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
    }

    return false;
  },
  message: 'This value must be a valid URL',
});
extend('name_type', {
  validate(value) {
    if (value) {
      return /([A-Z]*[a-z]*\.*\-*\s*[A-Z]*[a-z]*)*/.test(value);
    }

    return false;
  },
  message: 'This value must be a valid name',
});

extend("email", email);

// Install messages
localize({
  en
});

Vue.use(VueResource);
Vue.use(VueEditor);

Vue.filter('shape', function(text, length) {
    return text.length > length ? text.slice(0, length) + '...' : text;
});
Vue.filter('dateEU', function(date,formater) {
  return dayjs(date).format(formater);
});

// Vue.use(VueGtag, {
//     config: {
//         id: 'G-VJFVTHTJLP',
//         params: {
//             send_page_view: false
//         }
//     }
// }, router);
Vue.use(VueCookies, {
    expires: '7d'
});

var vm = new Vue({
    vuetify,
    el: '#app',
    router,
    i18n,
    store,
    render: h => h(App),
});

vm.$i18n.locale = 'en';
vm.$i18n.fallbackLocale = 'en';

