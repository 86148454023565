export default [
    {
        id: 20,
        name: 'vše'
    },
    {
        //article
        id: 1,
        name: 'článek',
    },
    {
        id: 2,
        name: 'kniha',
    },
    {
        id: 3,
        name: 'článek ve sborníku',
    },
    {
        id: 4,
        name: 'kapitola v knize',
    },
    {
        id: 5,
        name: 'zpráva',
    },
    {
        id: 6,
        name: 'preprint',
    },
    {
        id: 7,
        name: 'doktorská disertace'
    },
    // {
    //     id: 8,
    //     name: 'přednáška',
    // },
]
