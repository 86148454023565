<template>
  <div :key="keyrand"
    align-end justify-end column
    class="my-0 pb-0">
      <ul class="text-subtitle-2 nav nav-pills ml-0 my-0" style="z-index: 1000">
        <li v-for="(itemMenu, index) in mainMenu"
          :class="['blue--head','white--text','mr-2','mr-lg-4',{'dropup':itemMenu.id == idActiv},{'white':itemMenu.id == idActiv}]"
          :key="index" text
          @click="setSubmenu(itemMenu)"
          @mouseleave="setSubmenu(0)">
          <v-btn v-if="itemMenu.dropdown"
            :class="['white--text','dropdown-toggle','text-uppercase',{'blue-grey--text text--darken-3':itemMenu.id == idActiv}]"
            text large style="text-transform:none"
            >{{itemMenu.name[language]}}
            <span v-if="itemMenu.dropdown" :class="{dropup:itemMenu.id == idActiv}">
              <span class="dropup-toggle text-subtitle-2"></span>
            </span>
          </v-btn>
          <router-link v-else tag="v-btn"
            text small class=" white--text, blue--head" style=" border-style: hidden; text-transform:none"
            :to="itemMenu.url" >
            {{itemMenu.name.language}}
          </router-link>
          <ul v-if="itemMenu.id == idActiv" class="menudown ml-0 pl-0"  style="z-index: 100000 !important">
              <a  v-for="(item, index) in items" :key="index" :href="item.url" >
                <router-link style="z-index: 1000"
                    class="submenu ml-1 px-2 py-3 " tag="li"
                    exact :style="{ cursor: 'pointer'}"
                    :to="item.url"  active-class="active">
                    <span class="blue--text text-body-2">{{item.name[language]}} </span>
                </router-link>
            </a>
          </ul>
        </li>
      </ul>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {mapActions} from 'vuex';
export default{
  props: ['mainMenu','keyrand'],
  computed: {
    ...mapGetters('header',{
        items:'items',
        showItems:'showItems',
        idActiv:'idActiv',
        language:'language',
    })
  },
  methods:{
    ...mapActions('header',{
        setSubmenu: 'setSubmenu',
    }),
  }
}
</script>
