<template>
<div class="ml-0 mr-1 mr-md-2 mt-1 d-flex flex-row flex-wrap justify-start align-content-end " :key="Math.random()">
    <router-link  v-if="useractive.id > 0" :to="{name:'dashboardlist'}"  >
        <v-btn x-small  class="mx-0 px-0 light-green lighten-1 text-truncate ">
            <v-img class="mr-0 pa-0"
                :src="require('../../../assets/header/baseline-perm_identity-22px.svg')"
                min-height="20"
                max-height="20"
                contain>
            </v-img>
        </v-btn>
    </router-link>
    <router-link  v-else :to="{name:'userlogin'}" class="ma-0 pa-0" >
        <v-btn  x-small class= "px-0" :hidden="nameRoute == 'userlogin'">
            <v-img class="mr-0 pa-0"
                :src="require('../../../assets/header/baseline-perm_identity-22px.svg')"
                min-height="20"
                max-height="20"
                contain>
            </v-img>
        </v-btn>
    </router-link>

    <a>
    <v-btn x-small  class= "px-2 ml-2" @click="toggleLanguageClick()" >
       {{ lg[language] }}
    </v-btn>
    </a>
</div>
</template>
<script>

// import actions from store
import {mapActions} from 'vuex';
import {mapGetters} from 'vuex';

export default {
    data(){
        return{
            lg:{'cz':'ENG',
                'en':'CZ'},
        };
    },
    computed: {
        ...mapGetters('header',{
            language:'language',
            nameRoute:'nameRoute',
            useractive:'useractive',
        })
    },
    methods:{
        ...mapActions('header',{
            toggleLanguage:'toggleLanguage',
        }),
        toggleLanguageClick(){
            this.toggleLanguage(this.$i18n);
        },

    }
}
</script>
<style scoped>
.white--account{
    background-color: white;
}
</style>
