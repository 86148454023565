<template>
<div>
    <v-row v-if="drawer" class="ma-0 basic justify-end">
        <app-navigation-stable  v-if="width < 331" class="mt-4 mb-1 "></app-navigation-stable>
    </v-row>
    <v-row class="hidden-md-and-up basic ma-0 pa-0"  >
        <v-col cols="12" col-md-6 class="ma-0 pa-0">
            <ul class="mx-0 px-0 d-sm-none d-md-flex">
            <li v-for="(itemMenu, index) in mainMenu"
                :class="['mx-0','px-0','menudown',{'dropup':itemMenu.id == idActiv},{'white':itemMenu.id == idActiv}]"
                :key="index"
                @click="setSubmenu(itemMenu)">
                <v-btn
                :class="['dropdown-toggle','white--text',{'blue-grey--text text--darken-3':itemMenu.id == idActiv}]"
                text x-large
                >{{itemMenu.name[language]}}
                <span v-if="itemMenu.dropdown" :class="{dropup:itemMenu.id == idActiv}">
                    <span class="dropup-toggle"></span>
                </span>
                </v-btn>
                <ul v-if="itemMenu.id == idActiv" class=" mx-0 px-0" @click="toggleDrawer()">
                <router-link
                    v-for="(item, index) in items" :key="index"
                    class="submenu mx-0 px-0 py-3 "
                    :to="item.url" tag="li">
                    <span class="blue--text ml-4 pl-2">{{item.name[language]}}</span>
                </router-link>
                </ul>
            </li>
            </ul>
        </v-col>
    </v-row>
    <v-row justify="space-around" class="basic d-none d-sm-flex d-md-none" >
        <div  v-for="(itemMenu, index) in mainMenu"
            :class="['mx-0','px-0',{'dropup':itemMenu.id == idActiv},{'white':itemMenu.id == idActiv}]"
            :key="index"
            @click="setSubmenu(itemMenu)">
            <v-btn
                :class="['dropdown-toggle','white--text',{'blue-grey--text text--darken-3':itemMenu.id == idActiv},{'mr-8':index ==3}]"
                text medium
                >{{itemMenu.name[language]}}
                <span v-if="itemMenu.dropdown" :class="{dropup:itemMenu.id == idActiv}">
                    <span class="dropup-toggle"></span>
                </span>
            </v-btn>
            <ul v-if="itemMenu.id == idActiv" class="menudown-line mx-0 px-0" @click="toggleDrawer()">
                <router-link
                    v-for="(item, index) in items" :key="index"
                    class="submenu mx-0 pl-6 py-3 "
                    :to="item.url" tag="li">
                    <span class="blue--text body-2">{{item.name[language]}}</span>
                </router-link>
            </ul>
        </div>
    </v-row>
</div>
</template>
<script>
import {mapGetters} from 'vuex';
import {mapActions} from 'vuex';
import navigationStable from './NavigationStable.vue';

export default{
  props: ['mainMenu'],
  components:{
        AppNavigationStable: navigationStable,
  },
  computed: {
    ...mapGetters('header',{
        items:'items',
        showItems:'showItems',
        idActiv:'idActiv',
        language:'language',
        width:'width',
        drawer:'drawer',
    })
  },
  methods:{
    ...mapActions('header',{
        setSubmenu: 'setSubmenu',
        toggleDrawer:'toggleDrawer',
    }),
  }
}
</script>
<style>
ul {
  padding:0;
  margin:0;
  list-style-type:none;
}
.menudown-line {
    position: absolute;
    z-index: 1000;
    background-color: white;
    width: 100%;
    min-width: 220px;
    max-width: 260px;
    border-bottom-style: solid ;
    border-bottom-width: 1px;
    border-bottom-color: #2196F3;
    border-left-style: solid ;
    border-left-width: 1px;
    border-left-color: #2196F3;
    border-right-style: solid ;
    border-right-width: 1px;
    border-right-color: #2196F3;
}
li.menudown-line{
  border-bottom-style: solid ;
}
.submenu{
  border-top-style: solid ;
  border-top-width: 1px;
  border-top-color:#2196F3;
}
</style>
