<template>
 <v-card v-if="ready"  width="100%" class="pa-0 mx-0 my-0" style="z-index: 10; ">
<!------------HEADER ------------------->
    <v-row v-if="currentRouteName!='monitoring'" ref="mainrow" class="ma-0 basic justify-end" v-resize.initial="onResize">
        <!------------MENUS LINE----------------------->
        <!-- logo + title -->
        <router-link :to="{name:'home'}" >
           <div class="d-flex flex-row justify-start ma-0" >
                <v-img  :key="k"
                    :src="require('../../assets/header/'+headerImage)"
                    :max-height="maxHeight"
                    :max-width="maxWidth">
                </v-img>
            </div>
        </router-link>
        <div class="d-flex flex-column justify-start ma-0">
            <!----------Main menu---line------------->
            <app-main-menu  :keyrand="getRandomInt(10)" :mainMenu="mainMenu" class="mt-1 hidden-sm-and-down" ></app-main-menu>
            <!----------Bis menu---line------------->
            <app-bis-menu  :keyrand="getRandomInt(10)" :bisMenu="bisMenu" class="my-0 mb-0 hidden-sm-and-down "></app-bis-menu>
        </div>
        <v-spacer></v-spacer>
        <!----------Account + Language------------->
        <div  class="d-flex flex-column justify-start  mr-1  mr-md-3 mr-sm-5":key="Math.random()">
            <app-navigation-stable v-if="width >= 331" ></app-navigation-stable>
            <v-spacer></v-spacer>
            <div class="d-flex flex-row align-content-end flex-wrap ml-0 mr-1 mr-md-3 mt-0 mb-0" >
                <v-spacer></v-spacer>
                <v-btn color="#252697"  width="27px" small class=" hidden-md-and-up  mx-0 mb-2 px-0">
                    <i class="fa fa-bars" style="font-size:25px; color:white"  @click.stop="toggleDrawer()"></i>
                </v-btn>
            </div>
        </div>
    </v-row>
    <v-row v-else  class="mt-1 mb-0  mx-0" v-resize.initial="onResize">
        <!------------MENUS LINE----------------------->
        <!-- logo + title -->

           <div class="d-flex flex-row justify-start ma-0" >
                <v-img  :key="k"
                    :src="require('../../assets/header/'+headerImage)"
                    :max-height="maxHeight"
                    :max-width="maxWidth">
                </v-img>
            </div>
            <div class=" ml-15 pl-10  mt-4 mb-0 text-h5" >
                <span class="header-top  blue--text text--darken-4 font-weight-bold">
                Forthcoming events in the Institute
                </span>
            </div>
    </v-row>
    <v-row v-if="currentRouteName=='monitoring' && info" class="d-flex flex-row justify-end  mx-2 mt-n5 py-0">
        <p class="text-xs-right text-body-1 my-0 pt-n2" >{{ info }}</p>
    </v-row>
    <!------------MENUS SANDWICH------------------------>
    <!----------Main menu---sandwich------------->
    <app-main-menu-sandwich v-if="showMainMenuDown"  :mainMenu="mainMenu" hidden-md-and-up wrap></app-main-menu-sandwich>
    <!-------------bis menu sandwich------------------>
    <app-bis-menu-sandwich v-if="showBisMenuDown" :bisMenu="bisMenu" hidden-md-and-up wrap></app-bis-menu-sandwich>
 </v-card>
</template>
<script>
import resize from 'vue-resize-directive'
import menus from '../../data/header.js';

import {mapGetters} from 'vuex';
import {mapActions} from 'vuex';
// import components
import mainMenu from './menus/Mainmenu.vue';
import navigationStable from './menus/NavigationStable.vue';
import bisMenu from './menus/BisMenu.vue';
import mainMenuSandwich from './menus/MainMenuSandwich.vue';
import bisMenuSandwich from './menus/BisMenuSandwich.vue';

export default {
    directives: {
        resize,
    },
    data(){
        return{
            mainMenu: [],
            bisMenu: [],
            MembersMenu: [ 'Intranet','Webmail','Login' ],
            k:1,
            ready: false,
            info:''
        };
    },
    components:{
        AppMainMenu: mainMenu,
        AppNavigationStable: navigationStable,
        AppBisMenu: bisMenu,
        AppMainMenuSandwich: mainMenuSandwich,
        AppBisMenuSandwich: bisMenuSandwich,
    },
    created: function() {
        var main = [];
        var bis = [];
        menus.forEach(function(element) {
            if(element.type == 'main'){
                main.push(element);
            }
            else if(element.type == 'bis'){
                bis.push(element);
            }
        });
        this.mainMenu = main;
        this.bisMenu = bis;
        this.getInfo();

    },
    mounted () {
    //  console.log(this.$vuetify.breakpoint);
        this.set_width(this.$vuetify.breakpoint.width);
        this.set_MdAndDown(this.$vuetify.breakpoint.mdAndDown);
        this.set_mobile(this.$vuetify.breakpoint.mobile);
        this.set_xsOnly(this.$vuetify.breakpoint.xsOnly);
        this.set_SmAndDown(this.$vuetify.breakpoint.smAndDown);
        this.ready = true;
    },
    computed: {
        ...mapGetters('header',{
            drawer:'drawer',
            language:'language',
            width:'width',
            showBisMenuDown:'showBisMenuDown',
            showMainMenuDown:'showMainMenuDown',
            headerImage:'headerImage',
            maxHeight:'maxHeight',
            maxWidth:'maxWidth',
            useractive:'useractive',
        }),
        currentRouteName() {
            return this.$route.name;
        },
    },
    methods: {
        ...mapActions('header',{
            toggleDrawer:'toggleDrawer',
            set_width:'set_width',
            set_MdAndDown:'set_MdAndDown',
            set_mobile:'set_mobile',
            set_xsOnly:'set_xsOnly',
            set_SmAndDown:'set_SmAndDown',

        }),
        getRandomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        },
        onResize(e) {
            if (!e) return;
            this.scrollWidth = this.$refs.mainrow.scrollWidth;
            this.scrollHeight = this.$refs.mainrow.scrollHeight;
            this.set_width(this.scrollWidth);
        },
        async getInfo(){
            try{
                const result = await axios.get('/index.php/api/events/monitoring/info');
                if(result){
                    this.info = result.data;
                }
            }
            catch (error) {
                throw error;
            }
        },
    }
}
</script>
<style>
.col-centered{
    float: none;
}
.basic{
  background: #252697;
}
.white--text{
    color:white;
}
.blue--text{
    color:#3479b2;
}
ul.menudown{
  position: absolute;
  z-index:1000;
  background-color:white;
  width: 100%;
  min-width: 200px;
  max-width: 260px;
  border-bottom: 3px solid rgba(227, 226, 228, 0.6);
  border-left:3px solid rgba(227, 226, 228, 0.6);
  border-right:3px solid rgba(227, 226, 228, 0.6);
}
a, a:link, a:visited, a:active {
    text-decoration: none;
}

.header-top{
	/* letter-spacing: 1rem; */
    letter-spacing: 4px;
}
</style>
