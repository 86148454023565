/**
 *
 * menu LIBRARY
 */
const Library = resolve => {
    require.ensure(['../../components/library/Library.vue'], () => {
        resolve(require('../../components/library/Library.vue'));
    });
};
const LibraryIns = resolve => {
    require.ensure(['../../components/library/library/LibraryIns.vue'], () => {
        resolve(require('../../components/library/library/LibraryIns.vue'));
    });
};
const Journal = resolve => {
    require.ensure(['../../components/library/journals/Journal.vue'], () => {
        resolve(require('../../components/library/journals/Journal.vue'));
    });
};
 const Pattern = resolve => {
    require.ensure(['../../components/library/journals/zbMath/Pattern.vue'], () => {
        resolve(require('../../components/library/journals/zbMath/Pattern.vue'));
    });
};
const Instructions = resolve => {
    require.ensure(['../../components/library/journals/Instructions.vue'], () => {
        resolve(require('../../components/library/journals/Instructions.vue'));
    });
};

export const LibraryAddresses = [{
    path: '/index.php/library',
    name: 'library',
    component: Library,
    children: [
        { path: ':tab', name: 'libraryins', component: LibraryIns },
        { path: 'journal/:journal_id/:tab', name: 'journal', component: Journal },
        { path: 'journal/:journal_id/:tab/:issue', name: 'journal_issues', component: Journal },
        { path: 'zbmath/:tab', name: 'zbmath', component: Pattern },
        { path:'hstructures', name:'hstructures',
            beforeEnter() { location.href = 'https://higher-structures.math.cas.cz' },
        },
    ],  
}];
